import { axiosConfig } from "./axios.config";

const sendMessage = async (
    agent_email,
    chat_id,
    body,
    date,
    company
  ) => {
    const message = [{ 
        'type': "text",
        "content": {
            "preview_url": false,
            "body": body
        },
				date
    }]
    const obj = {
			agent_email,
      chat_id,
      messages : message,
    };
  
    const res = await axiosConfig.post(
      `${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/send_messages`,
      obj
    );
    
    return res.data;
};

// const sendMessage = async (
//     agent_id,
//     chat_id,
//     body,
//     date,
//     company
//   ) => {
//     console.log('body service: ', body)
//     const message = { 
//         'type': "text",
//         "content": {
//             "preview_url": false,
//             "body": body
//         },
// 				date

//     }
//     const obj = {
// 			agent_id,
//       chat_id,
//       message,
//     };
  
//     const res = await axiosConfig.post(
//       `${process.env.REACT_APP_API_NEW_URL}/agent/${company}/send_message`,
//       obj
//     );
    
//     return res.data;
//   };

// const sendMessage = async (agent_id, chat_id, body, date, company, type) => {
//   console.log('body service: ', body);

//   let message = {
//         type: "text",
//         content: {
//             preview_url: false,
//             body: body,
//             ...(type.video ? { video: type.video } : {}), 
//             ...(type.audio ? { audio: type.audio } : {}), 
//         },
//         date
//     };

    

//     console.log('file: ', file);
//   if (file.audio) {
//     message = {
//         type: "text",
//         content: {
//             preview_url: false,
//             body: body
//         },
//         date
//     };
//   } 

//   const obj = {
//       agent_id,
//       chat_id,
//       message,
// >>>>>>> b8c0fc059cfa63b4f91720054da2c0c151d2058d
//   };

//       console.log("Processing without file");
//       const response = await axios.post(
//           `${process.env.REACT_APP_API_NEW_URL}/agent/${company}/send_message`,
//           JSON.stringify(obj), // Envía obj como JSON
//           {
//               headers: {
//                   'Content-Type': 'application/json'
//               }
//           }
//       );
//       console.log('response: ', response);
//       return response.data;
// };


  

  const getFastMessages = async (company) => {
    const res = await axiosConfig.get(`${process.env.REACT_APP_API_NEW_URL}/agent/${company}/get_all_responses`);
    return res.data;
};

export { sendMessage, getFastMessages }