import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import "./MyInbox.css";
import { useQuery } from "@tanstack/react-query";
import { BiErrorCircle, BiTransfer } from "react-icons/bi";
import {
  BsCheck,
  BsFillClockFill,
  BsCheck2All,
  BsPersonDash,
  BsPersonAdd,
  BsStopwatchFill,
} from "react-icons/bs";
import { HiPencil } from "react-icons/hi";
import { AiFillSmile, AiFillFilter } from "react-icons/ai";
import { TbArchive, TbLockAccessOff } from "react-icons/tb";
import { SiRocketdotchat } from "react-icons/si";
import { RiMailSendLine } from "react-icons/ri";

import { RiChatOffLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { MyInboxProps } from "../../interfaces/myInboxProps.interface";
import {
  modifyChats,
  act_on_chats,
  getChat,
} from "../../services/ChatsActions.service";
import { io } from "socket.io-client";
import FileManagerComponent from "./../FileManager/FileManager";
import { sendMessage } from "../../services/Messages.service";
import emojiArr from "../emoji";
import userContext from "../../utils/userContext";
import Skeleton from "../Skeleton";
import moment from "moment-timezone";
import { FaRegCopy } from "react-icons/fa";
import FastAnswers from "../FastAnswers";
import Flag from "react-world-flags";
import parsePhoneNumber from "libphonenumber-js";
import logo from "../../images/Chatty_Logo02.png";
import UserModal from "../UserModal/UserModal";
import AudioPlayer from "../AudioPlayer";
import { ImageModal } from "../ImageModal";
import {
  AiOutlineClockCircle,
  AiOutlineMore,
  AiOutlineArrowLeft,
  AiOutlinePlus,
  AiOutlineEdit,
} from "react-icons/ai";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useAuth0 } from "@auth0/auth0-react";
import MessageInput from "../MessageInput/MessageInput";
import {
  MdOutlineMarkChatRead,
  MdOutlineMarkChatUnread,
  MdBlock,
} from "react-icons/md";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { agents } from "../../services/Agents.services";
import ModalTemplate from "../AddTemplate/ModalTemplate";

type Content = any;
interface Message {
  content: Content;
  created_at: Date;
  id: string;
  is_incoming_message: boolean;
  status:
    | "FAILED"
    | "READ"
    | "DELIVERED"
    | "SENT"
    | "WAITING"
    | "RECEIVED"
    | "SENT_TO_META"; //Ver que tipos vienen
  referral: null;
  subtype: null;
  type: string;
  update_at: Date;
  sent_by: string | null;
}

type Client = {
  DNI: string;
  country: string;
  email: string;
  has_photo: boolean;
  name: string;
  waid: string;
  photo: string;
  products: object[];
  sales: object[];
  quality: string;
};

type TimeLeft = {
  hover_description: string;
  status: string;
  time_left: string;
};

const MyInbox: React.FC<MyInboxProps> = ({
  panelToggle,
  setPanelToggle,
  inboxToggle,
  setInboxToggle,
  user: userId,
  emojiDropdownIsOpen,
  setEmojiDropdownIsOpen,
  bgColor,
  setFastAnswersIsOpen,
  fastAnswersIsOpen,
  botDropdownIsOpen,
  setBotDropdownIsOpen,
  setUserModalIsOpen,
  userModalIsOpen,
  countrySelectIsOpen,
  setCountrySelectIsOpen,
  setTickerIdSelectIsOpen,
  tickerIdSelectIsOpen,
  setRefetchPreview,
  setUser,
}) => {
  const {
    isModalOpen,
    setModalOpen,
    clientUser,
    setClientUser,
    agent,
    context,
    setAgent,
    setContext,
  } = useContext(userContext) as any;
  const dropdownAgent = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState<string>("");
  const [audioURL, setAudioURL] = useState("");
  const [messageAudio, setMessageAudio] = useState();
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [messagesCache, setMessagesCache] = useState<string[]>([]);
  const [messages, setMessages] = useState<any>([]);
  const [fileManager, setFileManager] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const [fileDataPreview, setFileDataPreview] = useState<any>();
  const dropAreaRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [clientData, setClientData] = useState<Client>({
    DNI: "",
    country: "",
    email: "",
    has_photo: false,
    name: "",
    waid: "",
    photo: "",
    products: [],
    sales: [],
    quality: "",
  });
  const [conversationType, setConversationType] = useState<string>("");
  const [showMessageCache, setShowMessageCache] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { logout, user } = useAuth0();
  const [agentsList, setAgentsList] = useState([]);
  const [agentDropdown, setAgentDropdown] = useState<boolean>(false);

  const [dropdownOptions, setDropdownOptions] = useState(false);
  const [size, setSize] = useState(20);
  const [isBroaderPreview, setIsBroaderPreview] = useState(false);
  const [showModalPlantillas, setShowModalPlantillas] = useState(false);

  const [leadQuality, setLeadQuality] = useState<string>("neutral");
  const [leadQualityIsOpen, setLeadQualityIsOpen] = useState<boolean>(false);
  const [chatAgentAsigned, setChatAgentAsigned] = useState<string>("");
  const [chatUnable, setChatUnable] = useState<TimeLeft>({
    hover_description: "",
    status: "",
    time_left: "",
  });
  const [isStarred, setIsStarred] = useState(false);
  const handleClick = useCallback(() => {
    setLeadQualityIsOpen((prevState) => !prevState);
  }, []);

  const phoneData = parsePhoneNumber(`+${clientUser?.phone_number}`);

  const {
    data: allMessages,
    isLoading: loadingMessages,
    refetch: refetchMessages,
  } = useQuery({
    queryFn: () =>
      getChat(
        agent.company,
        agent.id,
        clientUser.conversation_id,
        context === "admin" ? "chats_manager" : "agent_workspace"
      ),
    queryKey: ["getMessage", clientUser, size],
    enabled: !!clientUser.person_id && !fastAnswersIsOpen,
    keepPreviousData: false,
    cacheTime: 0,
    onError(data) {},
    onSuccess(data) {
      if (data.messages.length > messages.length) {
        console.log(data.messages);
        setMessagesCache([]);
        setShowMessageCache(false);
      }
      setIsStarred(data.starred);
      setChatAgentAsigned(data.agent_id);
      setChatUnable(data.time_left);
      setConversationType(data.area);
      setClientData(data.client);
      let messagesReverse = data.messages.reverse();
      setMessages(messagesReverse);
      if (data.client.quality != undefined) {
        setLeadQuality(data.client.quality);
      }
    },
  });

  const handleKeyDown = (event: any) => {
    if (message.length <= 1) {
      console.log("slash: ", event);
      // if(event.code === "Slash" && event.key === "/") {
      //   setFastAnswersIsOpen(true)
      // }
    }
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        if (message) {
          handleSendMessage(message, null);
          setMessage("");
          //@ts-ignore
        } else {
          console.log("message vacio");
        }
      }
    }
  };

  const getSentimentIcon = (leadQuality: string) => {
    switch (leadQuality) {
      case "good":
        return (
          <div>
            <SentimentSatisfiedAltIcon
              fontSize="small"
              className="text-[#08FBFF] z-0"
            />
          </div>
        );
      case "bad":
        return (
          <div>
            <SentimentVeryDissatisfiedIcon
              fontSize="small"
              className="text-[#08FBFF] z-0"
            />
          </div>
        );
      case "neutral":
      default:
        return (
          <div>
            <SentimentSatisfiedIcon
              fontSize="small"
              className="text-[#08FBFF] z-0"
            />
          </div>
        );
    }
  };

  const goToChat = () => {
    setContext("agent");
    window.localStorage.setItem("context", "agent");
    window.localStorage.setItem("infoChat", JSON.stringify(clientUser));
    console.log("clientUser: ", clientUser);
    setTimeout(() => {
      console.log("");
      setRefetchPreview(true);
      setUser(clientUser.conversation_id);
      // refetchMessages()
    }, 1500);
  };

  const copyCellphone = async () => {
    try {
      await navigator.clipboard.writeText(clientData.waid);
      toast.success("Numero copiado!", {
        autoClose: 2000,
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleSendMessage = async (text: any, type: any = {}) => {
    setMessagesCache([
      ...messagesCache,
      typeof text === "string" ? text : text.url,
    ]);
    setShowMessageCache(true);
    setSendLoading(true);
    try {
      // if (type.video) {
      //   console.log('Entra por video');

      //   await sendMessage(agent.id, clientUser.conversation_id, text.url, moment(new Date).format(), agent.company, {video: text.id})
      // } else if (type.audio) {
      //   console.log('Entra por audio');

      //   await sendMessage(agent.id, clientUser.conversation_id, text.url, moment(new Date).format(), agent.company, {audio: text.id})
      // } else {
      //   console.log('Entra por texto');

      await sendMessage(
        agent.id,
        clientUser.conversation_id,
        text,
        moment(new Date()).format(),
        agent.company
      );
      // }
      // Deberia enviar las peticiones de mensaje siempre al mismo EP con un x FormData que contenga los datos del mensaje y vaya subdividiendo los tipos para llegar al mensaje final, este seria image/file/text y estaria alojado en el s3 de chatty.
      // TODO
      //- modificar funcion SendMEssage para que reciba un formData
      //- agregar formData opcional en el EP send_message
      //- segun extension del link de s3 enviar el mensaje de x tipo
    } catch {
      refetchMessages();
      setSendLoading(false);
    } finally {
      refetchMessages();
      setSendLoading(false);
    }
  };

  useEffect(() => {
    setAgent({
      ...agent,
      id: user?.email,
      fullName: user?.name,
      email: user?.email,
    });
    const socket = io(`${process.env.REACT_APP_API_SOCKET_URL}`);
    socket.on(`newMessage`, () => {
      if (clientUser.conversation_id !== null) {
        console.log("get conver desde newMessage");
        refetchMessages();
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("is_broader_preview") === "true") {
      setIsBroaderPreview(true);
    } else {
      setIsBroaderPreview(false);
    }
    if (clientUser.conversation_id !== null) {
      refetchMessages();
    }
    return () => {
      setMessagesCache([]);
    };
  }, [clientUser]);

  useEffect(() => {
    if (clientUser.conversation_id !== null && !userModalIsOpen) {
      refetchMessages();
    }
  }, [userModalIsOpen]);

  useEffect(() => {
    setLoading(true);
    setSize(20);
  }, [userId]);

  useEffect(() => {
    return () => {
      setClientUser({});
    };
  }, [size]);

  useEffect(() => {
    return () => {
      setMessagesCache([]);
    };
  }, [clientUser]);

  useEffect(() => {
    setLoading(true);
    setSize(20);
  }, [userId]);

  // useEffect(() => {
  //   console.log('Hay que agregar el audio aca: ',message)
  // }, [messageAudio])

  const getAgents = async () => {
    try {
      const data = await agents(agent.company);
      setAgentsList(data);
      setAgentDropdown(true);
    } catch (e) {
      console.log("error en agents");
    }
  };

  const closeOpenMenus = (e: any) => {
    if (
      dropdownAgent.current &&
      agentDropdown &&
      !dropdownAgent.current.contains(e.target)
    ) {
      setAgentDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  useEffect(() => {
    refetchMessages();
  }, [showModalPlantillas]);

  const actionChat = async (
    action: string,
    autoAssign: boolean = false,
    agentTransfer: string = "",
    isUndo: boolean = false,
    refresh: boolean = true
  ) => {
    const conversationIdArray = [clientUser.conversation_id];
    const parameter = determineParameter(autoAssign, agentTransfer);
    try {
      const data = await actOnChats(
        action,
        conversationIdArray,
        parameter,
        isUndo
      );
      showToastWithUndoButton(data, action, autoAssign, agentTransfer);
      handlePostAction(action);
    } finally {
      setRefetchPreview(true);
    }
  };

  const determineParameter = (autoAssign: boolean, agentTransfer: string) => {
    if (agentTransfer) {
      setAgentDropdown(false);
      return agentTransfer;
    } else if (autoAssign) {
      return agent.email;
    }
    return "";
  };

  const actOnChats = (
    action: string,
    conversationIdArray: string[],
    parameter: string,
    isUndo: boolean = false
  ) => {
    const contextValue =
      context === "admin" ? "chats_manager" : "agent_workspace";
    return act_on_chats(
      agent.id,
      agent.company,
      !isUndo ? contextValue : "chats_manager",
      action,
      conversationIdArray,
      parameter
    );
  };

  const showToastWithUndoButton = (
    data: any,
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    const undoAction = getUndoAction(action, autoAssign, agentTransfer);
    toast.success(
      <div className="flex flex-col">
        {data}
        {autoAssign && (
          <button
            className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
            onClick={() => goToChat()}
          >
            Ir al chat
          </button>
        )}
        {undoAction}
      </div>,
      {
        autoClose: 2000,
      }
    );
  };

  const getUndoAction = (
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    if (context === "agent") {
      return (
        <button
          className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
          onClick={() => {
            actionChat("transfer_chats_to_agent", true, "", true, false);
          }}
        >
          Deshacer
        </button>
      );
    } else {
      const area = localStorage.getItem("currentArea");

      if (area === "WAITING AGENT") {
        switch (action) {
          case "archive_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() =>
                  actionChat("unarchive_chats", autoAssign, agentTransfer)
                }
              >
                Deshacer
              </button>
            );
          case "transfer_chats_to_agent":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("dessasign_agent_from_chats")}
              >
                Deshacer
              </button>
            );
          case "block_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("unblock_chats", true)}
              >
                Deshacer
              </button>
            );
          default:
            return null;
        }
      } else if (area === "ARCHIVED") {
        if (
          action === "unarchive_chats" ||
          action === "transfer_chats_to_agent" ||
          action === "block_chats"
        ) {
          return (
            <button
              className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
              onClick={() => actionChat("archive_chats")}
            >
              Deshacer
            </button>
          );
        }
      }
    }
  };

  const handlePostAction = (action: string) => {
    const actionsThatResetClientUser = [
      "mark_chats_as_unread",
      "archive_chats",
      "dessasign_agent_from_chats",
      "block_chats",
      "transfer_chats_to_agent",
    ];

    if (actionsThatResetClientUser.includes(action)) {
      setClientUser({});
    } else {
      refetchMessages();
    }
  };

  return (
    <div
      ref={dropAreaRef}
      className={`
      ${inboxToggle ? "flex" : "hidden md:flex"}
      ${panelToggle ? "hidden md:flex" : "flex"}
      h-full md:col-span-4 col-span-full md:flex flex-col justify-between bg-[#212944] border-x border-[#2f3652]`}
    >
      {isDragging && clientUser.conversation_id && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <p className="text-white text-xl">Suelta el archivo para subirlo</p>
        </div>
      )}
      {fileManager && (
        <FileManagerComponent
          company={agent.company}
          fileManager={fileManager}
          setFileManager={setFileManager}
          audioPreview={audioURL}
          setAudioPreview={setAudioURL}
          fileDataPreview={fileDataPreview}
          setFileDataPreview={setFileDataPreview}
          filePreview={filePreview}
          setFilePreview={setFilePreview}
        />
      )}
      <div
        className={`w-full basis-20 flex justify-between items-center px-6 border-b border-[#2f3652] ${bgColor} py-3.5 relative`}
      >
        <button
          className="flex md:hidden"
          onClick={() => setInboxToggle(false)}
        >
          <AiOutlineArrowLeft style={{ fontSize: 24, color: "white" }} />
        </button>
        <div className="basis-3/4">
          {!clientUser.name ? (
            <Skeleton nullMargin />
          ) : (
            <div className="items-center py-1 flex w-full md:w-auto">
              <div className="space-y-1 font-medium w-full md:w-auto">
                <div className="text-[#f9f8f8] flex w-full md:w-auto">
                  <div className="hidden sm:text-base mb-5 sm:mb-0 md:flex w-full md:w-auto justify-center rounded-3xl px-2">
                    <p className="text-[22px]">{clientData.name}</p>
                    {context === "agent" && !isBroaderPreview && (
                      <button
                        className="hidden md:block"
                        onClick={async (event) => {
                          event.stopPropagation();
                          setUserModalIsOpen(true);
                        }}
                      >
                        <HiPencil className="mt-1 mx-1 w-4" />
                      </button>
                    )}
                  </div>
                  <span
                    onClick={() => {
                      setDropdownOptions(false);
                      setPanelToggle(true);
                    }}
                    className="md:hidden text-[22px] mb-5 w-full justify-center text-center"
                  >
                    <p>{clientData.name}</p>
                  </span>
                  {userModalIsOpen && (
                    <UserModal
                      refetchMessages={refetchMessages}
                      setRefetchPreview={setRefetchPreview}
                      chatId={clientUser.conversation_id}
                      clientData={clientData}
                      setUserModalIsOpen={setUserModalIsOpen}
                      userModalIsOpen={userModalIsOpen}
                      countrySelectIsOpen={countrySelectIsOpen}
                      setCountrySelectIsOpen={setCountrySelectIsOpen}
                      setTickerIdSelectIsOpen={setTickerIdSelectIsOpen}
                      tickerIdSelectIsOpen={tickerIdSelectIsOpen}
                    />
                  )}
                </div>
                <div className="px-2 hidden md:flex mt-1 gap-3">
                  <Flag
                    code={phoneData?.country}
                    className="w-5 h-5 opacity-60"
                  />
                  <div
                    className="flex gap-1 text-base text-[#ACB8C0] truncate items-center cursor-pointer"
                    onClick={() => copyCellphone()}
                  >
                    <FaRegCopy />+{clientData.waid}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col h-full">
          {!isBroaderPreview && (
            <div
              className={`${
                !clientUser.phone_number ? "hidden" : "flex h-full items-start"
              }`}
            >
              {/* Menu actions mobile */}
              <button
                className="flex md:hidden"
                onClick={() => setDropdownOptions(!dropdownOptions)}
              >
                <AiOutlineMore style={{ fontSize: 24, color: "white" }} />
              </button>
              <div
                className={`${
                  dropdownOptions
                    ? "z-10 flex absolute px-3 top-[84px] right-1 flex-col rounded-lg"
                    : "hidden"
                } md:flex ${bgColor} md:bg-none`}
              >
                {context === "admin" && (
                  <div
                    className={`flex md:block w-auto md:w-10 items-center h-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl ${
                      conversationType === "WITH AGENT"
                        ? ""
                        : "hover:bg-[#3b4a54]"
                    }`}
                  >
                    {chatAgentAsigned === agent.email ? (
                      <>
                        <BsPersonDash
                          size={25}
                          className={`text-[#ACB8C0]`}
                          title="Desasignarme el chat"
                          onClick={() =>
                            actionChat("dessasign_agent_from_chats")
                          }
                        />
                        <span className="md:hidden text-xs text-white ml-2">
                          Desasignarme el chat
                        </span>
                      </>
                    ) : (
                      <>
                        <BsPersonAdd
                          size={25}
                          className={`text-[#ACB8C0]`}
                          title="Asignarme el chat"
                          onClick={() =>
                            actionChat("transfer_chats_to_agent", true)
                          }
                        />
                        <span className="md:hidden text-xs text-white ml-2">
                          Asignarme el chat
                        </span>
                      </>
                    )}
                  </div>
                )}
                {context === "agent" && (
                  <>
                    <div
                      className={`flex md:block w-auto md:w-10 items-centerh-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl hover:bg-[#3b4a54]`}
                    >
                      {isStarred ? (
                        <>
                          <AiFillStar
                            size={25}
                            className="text-[#cfca6d]"
                            title="Agregar como destacado"
                            onClick={() =>
                              actionChat("mark_chats_as_unstarred")
                            }
                          />
                          <span className="md:hidden text-xs text-white ml-2">
                            No destacar chat
                          </span>
                        </>
                      ) : (
                        <>
                          <AiOutlineStar
                            size={25}
                            className="text-[#ACB8C0]"
                            title="Agregar como destacado"
                            onClick={() => actionChat("mark_chats_as_starred")}
                          />
                          <span className="md:hidden text-xs text-white ml-2">
                            Destacar chat
                          </span>
                        </>
                      )}
                    </div>
                    <div
                      className={`flex md:block w-auto md:w-10 items-center h-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl ${
                        conversationType === "WITH AGENT"
                          ? ""
                          : "hover:bg-[#3b4a54]"
                      }`}
                    >
                      <MdOutlineMarkChatUnread
                        size={25}
                        className="text-[#ACB8C0]"
                        title="Marcar chat como no leido"
                        onClick={() => actionChat("mark_chats_as_unread")}
                      />
                      <span className="md:hidden text-xs text-white ml-2">
                        Marcar chat como no leido
                      </span>
                    </div>
                    <div
                      className={`flex md:block w-auto md:w-10 items-centerh-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl hover:bg-[#3b4a54]`}
                    >
                      <BsPersonDash
                        size={25}
                        className="text-[#ACB8C0]"
                        title="Desasignar chat"
                        onClick={() => actionChat("dessasign_agent_from_chats")}
                      />
                      <span className="md:hidden text-xs text-white ml-2">
                        Desasignar chat
                      </span>
                    </div>
                    {context === "agent" && (
                      <div
                        className={`flex md:hidden w-auto md:w-10 items-centerh-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl hover:bg-[#3b4a54]`}
                      >
                        <AiOutlineEdit
                          size={25}
                          className="text-[#ACB8C0]"
                          title="Editar"
                          onClick={async (event) => {
                            event.stopPropagation();
                            setUserModalIsOpen(true);
                          }}
                        />
                        <span className="md:hidden text-xs text-white ml-2">
                          Editar
                        </span>
                      </div>
                    )}
                    {/* <div onClick={handleClick} className={`flex md:hidden w-auto md:w-10 items-centerh-10 p-2 mx-1  hover:cursor-pointer trainsition-all duration-200 rounded-3xl hover:bg-[#3b4a54]`}>
                        <AiFillSmile
                          size={25}
                          className="text-[#04fbeb]"
                          title="Calidad del Lead"
                        />
                        <span className="md:hidden text-xs text-white ml-2">Calidad del Lead</span>
                      </div> */}
                  </>
                )}
                <div
                  onClick={() => {
                    setDropdownOptions(false);
                    setPanelToggle(true);
                  }}
                  className="flex md:hidden w-auto md:w-10 items-center h-10 p-2 mx-1 hover:bg-[#3b4a54] hover:cursor-pointer trainsition-all duration-200 rounded-3xl"
                >
                  <AiFillFilter size={25} className={`text-[#ACB8C0]`} />
                  <span className="md:hidden text-xs text-white ml-2">
                    Panel del chat
                  </span>
                </div>
                <div className="flex relative md:block w-auto md:w-10 items-center h-10 p-2 mx-1 hover:bg-[#3b4a54] hover:cursor-pointer trainsition-all duration-200 rounded-3xl">
                  <BiTransfer
                    onClick={(e) => {
                      e.stopPropagation();
                      getAgents();
                      setAgentDropdown(true);
                    }}
                    size={25}
                    className={`text-[#ACB8C0] ${
                      conversationType === "ARCHIVED" ? "disable-btn" : ""
                    }`}
                    title="Transferir agente"
                  />
                  <span className="md:hidden text-xs text-white ml-2">
                    Transferir agente
                  </span>
                  {agentDropdown && (
                    <div
                      ref={dropdownAgent}
                      className=" dropdownContainer !w-[100px] border-white border !min-w-[200px] left-[50px] top-[140px] bg-[#121827] max-h-[200px] overflow-y-auto overflow-x-hidden"
                    >
                      {/* @ts-ignore */}
                      {agentsList.map((agent) => (
                        <button
                          className="hover:text-[#20cab4]"
                          onClick={() =>
                            actionChat(
                              "transfer_chats_to_agent",
                              false,
                              //@ts-ignore
                              agent.email
                            )
                          }
                        >
                          {/* @ts-ignore */}
                          {agent.nombre}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="flex md:block w-auto md:w-10 items-center h-10 p-2 mx-1 hover:bg-[#3b4a54] hover:cursor-pointer trainsition-all duration-200 rounded-3xl">
                  <TbArchive
                    onClick={() => actionChat("archive_chats")}
                    size={25}
                    className={`text-[#ACB8C0] ${
                      conversationType === "ARCHIVED" ? "disable-btn" : ""
                    }`}
                    title="Archivar chat"
                  />
                  <span className="md:hidden text-xs text-white ml-2">
                    Archivar chat
                  </span>
                </div>
                <div
                  className={`flex md:block w-auto md:w-10 items-center h-10 p-2 mx-1 hover:cursor-pointer trainsition-all duration-200 rounded-3xl ${
                    context === "admin" && conversationType === "WITH AGENT"
                      ? ""
                      : "hover:bg-[#3b4a54]"
                  }`}
                >
                  <MdBlock
                    size={25}
                    title="Bloquear conversación"
                    onClick={() => actionChat("block_chats")}
                    className={`text-[#ACB8C0] ${
                      context === "admin" && conversationType === "WITH AGENT"
                        ? "disable-btn"
                        : ""
                    }`}
                  />
                  <span className="md:hidden text-xs text-white ml-2">
                    Bloquear
                  </span>
                </div>
              </div>
            </div>
          )}
          {clientUser.name && (
            <div className="flex justify-end items-center">
              <BsStopwatchFill
                style={{
                  fontSize: 24,
                  fill: "#121827",
                  background:
                    chatUnable.status == "yellow"
                      ? "#CFCA6D"
                      : chatUnable.status == "green"
                      ? "#08AA95"
                      : chatUnable.status == "grey"
                      ? "#CCCCCC"
                      : "#FF5757",
                }}
                className="text-[#212944] font-[30px] p-1 bg-[#2baa95] rounded-full overflow-hidden mr-2"
              />
              <span className="text-sm text-[#acb8c0]">
                {chatUnable.time_left}
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={`w-full ${
          context !== "admin" ? " basis-3/4 " : "basis-4/4 pb-6"
        } bg-[#212944] dark:bg-white flex flex-col-reverse px-6 overflow-scroll overflow-x-hidden whitespace-pre-wrap`}
      >
        {!clientUser?.phone_number ? (
          <div className="flex justify-center align-middle mb-14">
            <img className=" w-96 h-96" src={logo} />
          </div>
        ) : loadingMessages ? (
          Array.from({ length: 7 }).map((_, i) => <Skeleton key={i} />)
        ) : (
          <div className="flex flex-col-reverse">
            <div className="flex flex-col">
              {messagesCache?.map((messageCache, index) => {
                return (
                  <div
                    key={index}
                    className={` ${
                      messageCache.length ? "flex flex-col" : "hidden"
                    } min-h-fit md:max-w-[45%] max-w-[45%] mt-3 bg-[#1f6466] text-white py-1 px-[18px] rounded-3xl rounded-br-none md:text-base text-xs self-end break-words block`}
                  >
                    {/* <div className="text-sm text-principalColor">
                      {agent.fullName ? agent.fullName : "Nombre de Agente"}
                    </div> */}
                    {messageCache &&
                      messageCache
                        .replace(/\s*\\n\s*/g, "\n")
                        .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                        .map((part: any, index: any) => {
                          if (part.startsWith("*") && part.endsWith("*")) {
                            return (
                              <span key={index} className="font-bold">
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (
                            part.startsWith("_") &&
                            part.endsWith("_")
                          ) {
                            return (
                              <span key={index} className="italic">
                                {part.slice(1, -1)}
                              </span>
                            );
                          } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                            if (
                              /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(part)
                            ) {
                              return (
                                <div className="flex flex-col">
                                  <img key={index} src={part} alt="" />
                                  <ImageModal index={index} part={part} />
                                  <p>Cargando...</p>
                                </div>
                              );
                            } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                              return (
                                <video key={index} controls>
                                  <source src={part} type="video/mp4" />
                                </video>
                              );
                            } else if (/\.(ogg)$/i.test(part)) {
                              return <AudioPlayer audioUrl={part} />;
                              //docs next
                            } else if (/\.pdf$/i.test(part)) {
                              return (
                                <iframe>
                                  <object data={part} type="application/pdf">
                                    <embed src={part} type="application/pdf" />
                                  </object>
                                  <p>Cargando...</p>
                                </iframe>
                              );
                            } else if (/\.docs$/i.test(part)) {
                              return (
                                <iframe>
                                  <object data={part} type="application/msword">
                                    <embed
                                      src={part}
                                      type="application/msword"
                                    />
                                  </object>
                                  <p>Cargando...</p>
                                </iframe>
                              );
                            } else {
                              return (
                                <a
                                  key={index}
                                  className="text-principalColor"
                                  href={part}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {part}
                                </a>
                              );
                            }
                          } else if (part === "\n") {
                            return <br key={index} />;
                          } else {
                            return <span key={index}>{part}</span>;
                          }
                        })}

                    <div className="flex text-xs justify-end text-gray-300">
                      {moment().format("LT")}
                      {sendLoading ? (
                        <BsFillClockFill
                          size={13}
                          style={{
                            width: "16px",
                            height: "13px",
                            marginLeft: "2px",
                          }}
                        />
                      ) : (
                        <BsCheck size={18} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {messages?.map((messageItem: Message, index: number, arr: any) => {
              return (
                <div className="w-full flex flex-col globo" key={index}>
                  {moment(messageItem?.created_at).format("DD/MM/YYYY") !==
                    moment(arr[index + 1]?.created_at).format("DD/MM/YYYY") && (
                    <div className="flex justify-center text-sm text-white bg-[#20CAB4] mt-4 m-auto p-2 px-4 rounded-lg my-1">
                      {moment(messageItem.created_at).format("dddd DD/MM/YYYY")}
                    </div>
                  )}
                  <div
                    className={`${
                      messageItem.type === "central" ? "flex" : "hidden"
                    } justify-center text-sm mt-4 text-[#f1edec] bg-[#1a2238] m-auto px-2 py-1 rounded-lg my-1`}
                  >
                    {moment(messageItem.created_at).format("DD/MM/YYYY")}{" "}
                    {messageItem.content.body}
                  </div>
                  {messageItem.is_incoming_message && (
                    <div className={`items-end mt-3 flex`}>
                      <div className="min-h-fit md:max-w-[85%] max-w-[75%] bg-[#4B5467] py-2 px-[18px] rounded-3xl rounded-bl-none md:text-base text-xs break-words">
                        <div className="flex items-center">
                          <div className="text-base text-[#a6a6a6] font-bold mr-2">
                            {moment(messageItem.created_at).format("LT")}
                          </div>
                          <p>
                            <b className="text-[#a6a6a6] font-bold">
                              {clientUser.name}
                            </b>
                          </p>
                        </div>
                        {messageItem.content.body &&
                          messageItem.content.body
                            .replace(/\s*\\n\s*/g, "\n")
                            .replace(/Descargar\s*-->\s*/, "")
                            .replace(/Caption:\s*/, "")
                            .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                            .map((part: any, index: any) => {
                              if (part.startsWith("*") && part.endsWith("*")) {
                                return (
                                  <span key={index} className="font-bold">
                                    {part.slice(1, -1)}
                                  </span>
                                );
                              } else if (
                                part.startsWith("_") &&
                                part.endsWith("_")
                              ) {
                                return (
                                  <span key={index} className="italic">
                                    {part.slice(1, -1)}
                                  </span>
                                );
                              } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                                if (
                                  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
                                    part
                                  )
                                ) {
                                  return (
                                    <div className="flex flex-col">
                                      <img key={index} src={part} alt="" />
                                      <ImageModal index={index} part={part} />
                                      {
                                        //@ts-ignore
                                        messageItem.content.caption && (
                                          <p className="mt-1">
                                            {messageItem.content.caption}
                                          </p>
                                        )
                                      }
                                    </div>
                                  );
                                } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                                  return (
                                    <video key={index} controls>
                                      <source src={part} type="video/mp4" />
                                    </video>
                                  );
                                } else if (/\.(ogg|mpeg|mp3)$/i.test(part)) {
                                  return <AudioPlayer audioUrl={part} />;
                                } else if (part.includes("mp3")) {
                                  return <AudioPlayer audioUrl={part} />;
                                } else {
                                  return (
                                    <a
                                      key={index}
                                      className="text-principalColor"
                                      href={part}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {part}
                                    </a>
                                  );
                                }
                              } else if (part === "\n") {
                                return <br key={index} />;
                              } else {
                                return (
                                  <span className="text-[#F1EDEC]" key={index}>
                                    {part}
                                  </span>
                                );
                              }
                            })}
                      </div>
                    </div>
                  )}
                  {!messageItem.is_incoming_message &&
                    messageItem.type !== "central" && (
                      <div
                        className={`bg-[#1F6466] min-h-fit md:max-w-[100%] max-w-[100%] mt-3 text-white py-1 px-[18px] rounded-3xl rounded-br-none md:text-base text-xs self-end break-words block`}
                      >
                        {/* <div className={`text-sm text-principalColor`}>
                    {messageItem.is_incoming_message
                      ? ''
                      : messageItem?.agent?.name ? messageItem?.agent?.name : "Nombre de Agente"}
                  </div> */}
                        {messageItem.sent_by !== null && (
                          <div className="flex items-center">
                            <div className="text-base text-[#a6a6a6] font-bold mr-2">
                              {moment(messageItem.created_at).format("LT")}
                            </div>
                            <p>
                              <b className="text-[#a6a6a6] font-bold">
                                {messageItem.sent_by}
                              </b>
                            </p>
                          </div>
                        )}

                        {messageItem.content.body &&
                          messageItem.content.body
                            .replace(/\s*\\n\s*/g, "\n")
                            .split(/(\*.*?\*|_.*?_|\bhttps?:\/\/\S+\b|\n)/)
                            .map((part: any, index: any) => {
                              if (part.startsWith("*") && part.endsWith("*")) {
                                return (
                                  <span key={index} className="font-bold">
                                    {part.slice(1, -1)}
                                  </span>
                                );
                              } else if (
                                part.startsWith("_") &&
                                part.endsWith("_")
                              ) {
                                return (
                                  <span key={index} className="italic">
                                    {part.slice(1, -1)}
                                  </span>
                                );
                              } else if (/\bhttps?:\/\/\S+\b/.test(part)) {
                                if (
                                  /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(
                                    part
                                  )
                                ) {
                                  return (
                                    <div className="flex flex-col">
                                      <img key={index} src={part} alt="" />
                                      <ImageModal index={index} part={part} />
                                      {
                                        //@ts-ignore
                                        messageItem.content.caption && (
                                          <p className="mt-1">
                                            {messageItem.content.caption}
                                          </p>
                                        )
                                      }
                                    </div>
                                  );
                                } else if (/\.(mp4|avi|mov|wmv)$/i.test(part)) {
                                  return (
                                    <video key={index} controls>
                                      <source src={part} type="video/mp4" />
                                    </video>
                                  );
                                } else if (/\.(ogg|mpeg|mp3)$/i.test(part)) {
                                  return <AudioPlayer audioUrl={part} />;
                                } else if (
                                  /\.(pdf|docx?|pptx?|xlsx?|txt|rtf)$/i.test(
                                    part
                                  )
                                ) {
                                  return (
                                    <div
                                      key={index}
                                      className="flex flex-col items-center space-x-2"
                                    >
                                      <iframe
                                        src={part}
                                        className="w-full h-32 m-1" // Ajusta el ancho (w-full) y la altura (h-64) según tus necesidades
                                        title="Vista previa del documento"
                                      ></iframe>

                                      <a
                                        href={part}
                                        download
                                        className="text-principalColor m-1 hover:underline"
                                        target="_blank"
                                      >
                                        Ver Documento
                                      </a>
                                      <button
                                        onClick={() =>
                                          window.open(part, "_blank")
                                        }
                                        className="bg-principalColor text-white px-2 py-1 rounded-md"
                                      >
                                        Descargar
                                      </button>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <a
                                      key={index}
                                      className="text-principalColor"
                                      href={part}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {part}
                                    </a>
                                  );
                                }
                              } else if (part === "\n") {
                                return <br key={index} />;
                              } else {
                                return (
                                  <span className="text-[#F1EDEC]" key={index}>
                                    {part}
                                  </span>
                                );
                              }
                            })}
                        <div className="flex text-xs justify-end text-gray-300">
                          {messageItem.status === "WAITING" ||
                            (messageItem.status === "SENT_TO_META" && (
                              <BsFillClockFill size={18} />
                            ))}
                          {messageItem.status === "SENT" ||
                            (messageItem.status === "DELIVERED" && (
                              <BsCheck size={18} />
                            ))}
                          {messageItem.status === "RECEIVED" && (
                            <div>
                              <BsCheck2All size={18} />
                            </div>
                          )}
                          {messageItem.status === "READ" && (
                            <div className="read-icons">
                              <BsCheck2All size={18} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  {messageItem.status === "FAILED" && (
                    <div className="text-red-500 flex self-end items-center text-sm">
                      <div>Error al enviar el mensaje</div>
                      <BiErrorCircle color="red" size={18} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={`w-full h-auto basis-24 flex items-center justify-center px-6 my-5 ${
          clientUser.name?.length > 0 ? "" : "pointer-events-none"
        }`}
      >
        {context !== "admin" &&
          chatUnable.status !== "grey" &&
          !isBroaderPreview && (
            <div
              className={`w-full h-auto flex mt-5 mb-[-22px]${
                clientUser.name?.length > 0 ? "" : "pointer-events-none"
              }`}
            >
              <div
                className={`px-3 py-2 rounded flex w-full items-end ${
                  clientUser.name?.length > 0 ? "bg-darkBackground" : "hidden"
                }`}
              >
                {context === "agent" && (
                  <FastAnswers
                    updateInput={setMessage}
                    fastAnswersIsOpen={fastAnswersIsOpen}
                    setFastAnswersIsOpen={setFastAnswersIsOpen}
                    handleSendMessage={sendLoading}
                  />
                )}
                <form
                  className={`w-full flex justify-between items-end
              ${context === "admin" ? " disable-form" : ""}
              `}
                  onSubmit={(e) => {
                    e.preventDefault();
                    // handleSendMessage(message);
                  }}
                >
                  {emojiDropdownIsOpen ? (
                    <div
                      className={`absolute max-h-72 overflow-scroll grid overflow-x-hidden bottom-20 grid-rows-auto grid-cols-6 p-1 rounded-2xl rounded-bl-none bg-slate-50 list-none emoji-dropdown-container`}
                    >
                      {emojiArr.map((emoji, index) => (
                        <li
                          className="text-3xl cursor-pointer"
                          key={index}
                          onClick={() => setMessage(message.concat(emoji))}
                        >
                          {emoji}
                        </li>
                      ))}
                    </div>
                  ) : (
                    <div
                      className={`absolute max-h-72 overflow-scroll hidden overflow-x-hidden bottom-20 grid-rows-auto grid-cols-6 p-1 rounded-2xl rounded-bl-none bg-slate-50 list-none`}
                    >
                      {emojiArr.map((emoji, index) => (
                        <li
                          className="text-3xl cursor-pointer"
                          key={index}
                          onClick={() => setMessage(message.concat(emoji))}
                        >
                          {emoji}
                        </li>
                      ))}
                    </div>
                  )}

                  <MessageInput
                    setMessageAudio={setMessageAudio}
                    onSendMessage={handleSendMessage}
                    message={message}
                    setMessage={setMessage}
                    emojiDropdown={setEmojiDropdownIsOpen}
                    setFileManager={setFileManager}
                    audioURL={audioURL}
                    setAudioURL={setAudioURL}
                    filePreview={filePreview}
                    setFilePreview={setFilePreview}
                    fileDataPreview={fileDataPreview}
                    setFileDataPreview={setFileDataPreview}
                    dropAreaRef={dropAreaRef}
                    setIsDragging={setIsDragging}
                    setFastAnswersIsOpen={setFastAnswersIsOpen}
                  />
                </form>
              </div>
            </div>
          )}
        {context !== "admin" &&
          chatUnable.status === "grey" &&
          clientUser.conversation_id !== undefined && (
            <>
              <div
                className={`px-3 py-2 flex w-full items-center justify-between gap-2`}
              >
                <p className="text-white text-sm">
                  Conversacion libre finalizada
                  <br />
                  Conversaciones abiertas: Servicio a atencion al cliente
                  (restan 48hs)
                  <br />
                  Envia plantillas de mensajes sin cargo para las categorias de
                  conversaciones abiertas.
                </p>
                <button
                  onClick={() => setShowModalPlantillas(true)}
                  className="px-4 py-3 bg-[#20cab4] flex items-center justify-center rounded"
                >
                  <RiMailSendLine className="text-white text-[24px]" />
                  <span className="text-white">Plantillas</span>
                </button>
              </div>
              {showModalPlantillas && (
                <ModalTemplate
                  onClose={() => setShowModalPlantillas(false)}
                  isFromChat={true}
                />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default MyInbox;
