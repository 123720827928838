import React, { useContext, useState, useRef, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { act_on_chats } from "../../services/ChatsActions.service";
import { BsXLg, BsPersonDash } from "react-icons/bs";
import { MdMarkChatUnread } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";

import { AiOutlineCheckSquare, AiOutlineArrowRight } from "react-icons/ai";
import { Conversation } from "../Conversation";
import {
  context,
  PreviewInterface,
  ConversationsProps,
} from "../../interfaces";
import userContext from "../../utils/userContext";
import OpenModal from "../OpenModal";
import Skeletion from "../Skeleton";
import "./Conversations.css";
import { FiArchive } from "react-icons/fi";
import { agents } from "../../services/Agents.services";

export const Conversations: React.FC<ConversationsProps> = ({
  isLoading,
  conversations,
  setInboxToggle,
  setUser,
  isPreviousData,
  skeletonRef,
  checkConversations,
  refetchMyConversations,
  searchRef,
  refetch,
  filter,
  isSelectChatOpen,
  setFilterSelection,
  isBroaderPreview = false,
}) => {
  const {
    setClientUser,
    clientUser,
    agent,
    isAlert,
    setIsAlert,
    isModalOpen,
    setModalOpen,
    context,
    setContext,
  } = useContext(userContext) as context;

  const [checked, setChecked] = useState<any>([]);
  const [openSlide, setopenSlide] = useState<boolean>(false);
  const [agentDropdown, setAgentDropdown] = useState<boolean>(false);
  const [agentsList, setAgentsList] = useState([]);
  const dropdownAgent = useRef<HTMLDivElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const selectAll = () => {
    const actualList = [...checked];
    const allSelectedPage = conversations.map(
      (item: PreviewInterface) => item.chat_id
    );
    const updatedList = actualList.concat(allSelectedPage);

    function removeDuplicates(arr: string[]) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }
    setChecked(removeDuplicates(updatedList));
  };

  const handleLeaveMultipleChats = useMutation(
    () =>
      act_on_chats(
        agent.id,
        agent.company,
        "agent_workspace",
        "dessasign_agent_from_chats",
        [checked]
      ),
    {
      onSuccess: (res) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: `Dejó ${res} chats correctamente`,
          type: "success",
        });
        refetchMyConversations().finally(() => {
          setChecked([]);
          setClientUser({});
        });
      },
      onError: (err) => {
        setIsAlert({
          ...isAlert,
          isOpen: true,
          title: "Ocurrió un error al tratar de dejar los chats",
          type: "failure",
        });
        setChecked([]);
        setClientUser({});
      },
      onMutate: () => {
        setModalOpen({ ...isModalOpen, leaveSelected: false });
        refetchMyConversations();
      },
    }
  );

  const handleRefetch = () => {
    refetch();
  };

  const getAgents = async () => {
    try {
      const data = await agents(agent.company);
      setAgentsList(data);
      setAgentDropdown(true);
    } catch (e) {
      console.log("error en agents");
    }
  };

  const sortedConversations = conversations.sort((a, b) => {
    if (a.is_archived === 1 && b.is_archived === 0) {
      return 1;
    }
    if (a.is_archived === 0 && b.is_archived === 1) {
      return -1;
    }
    return 0;
  });

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  useEffect(() => {
    if (context === "agent") {
      //@ts-ignore
      if (window.localStorage.getItem("infoChat")) {
        type previousChatType = {
          conversation_id: string;
          name: string;
          img: string;
          phone_number: string;
        };
        let previousChat: previousChatType = JSON.parse(
          //@ts-ignore
          window.localStorage.getItem("infoChat")
        );
        console.log(previousChat);
        handleRefetch();
        setInboxToggle(true);
        setUser(previousChat.conversation_id);
        setClientUser({
          name: previousChat.name,
          img: previousChat.img,
          conversation_id: previousChat.conversation_id,
          phone_number: previousChat.phone_number,
          //@ts-ignore
          tags: previousChat.tags,
          //@ts-ignore
          products: previousChat.products,
          //@ts-ignore
          sales: previousChat.sales,
        });
        window.localStorage.removeItem("infoChat");
      }
    }
  }, [context]);

  const closeOpenMenus = (e: any) => {
    if (dropdown.current && openSlide && !dropdown.current.contains(e.target)) {
      setopenSlide(false);
    }
  };
  // const actionChat = async (action:string, autoAssign:boolean = false, agentTransfer:string = '') => {
  //   try {
  //     let parameter = ''

  //     if(agentTransfer !== '') {
  //       parameter = agentTransfer
  //       setAgentDropdown(false)
  //     } else if(autoAssign) {
  //       parameter = agent.id
  //     }
  //     const data = await act_on_chats(agent.id, agent.company, context === 'admin' ? 'chats_manager' :'agent_workspace', action, checked, parameter)
  //     setChecked([])
  //     setopenSlide(false)
  //     toast.success(
  //       <div className="flex flex-col">
  //         {data}
  //         {
  //           (autoAssign && checked.length === 1) && <button className="mt-2 bg-[#0daa96] px-2 py-1 text-white" onClick={() => gotToChat()}>Ir al chat</button>
  //         }

  //       </div>,
  //       { autoClose: 2000 }
  //     );
  //     handleRefetch()
  //   }
  //   catch {
  //     toast.error('Error la realizar accion', {
  // 			autoClose: 2000,
  // 		})
  //   }
  // }

  const goToChat = () => {
    setContext("agent");
    window.localStorage.setItem("context", "agent");
    window.localStorage.setItem("infoChat", JSON.stringify(clientUser));
    console.log("clientUser: ", clientUser);
    setTimeout(() => {
      // setUser(clientUser.conversation_id)
      handleRefetch();
    }, 1500);
  };
  // New Action undo chats

  const actionChat = async (
    action: string,
    autoAssign: boolean = false,
    agentTransfer: string = "",
    isUndo: boolean = false
  ) => {
    // const conversationIdArray = [conversation_id];
    const parameter = determineParameter(autoAssign, agentTransfer);

    try {
      const data = await actOnChats(action, checked, parameter, isUndo);
      showToastWithUndoButton(data, action, autoAssign, agentTransfer);
      handlePostAction(action);
    } finally {
      handleRefetch();
    }
  };

  const determineParameter = (autoAssign: boolean, agentTransfer: string) => {
    if (agentTransfer) {
      setAgentDropdown(false);
      return agentTransfer;
    } else if (autoAssign) {
      return agent.email;
    }
    return "";
  };

  const actOnChats = (
    action: string,
    conversationIdArray: string[],
    parameter: string,
    isUndo: boolean = false
  ) => {
    const contextValue =
      context === "admin" ? "chats_manager" : "agent_workspace";
    return act_on_chats(
      agent.id,
      agent.company,
      !isUndo ? contextValue : "chats_manager",
      action,
      conversationIdArray,
      parameter
    );
  };

  const showToastWithUndoButton = (
    data: any,
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    const undoAction = getUndoAction(action, autoAssign, agentTransfer);
    toast.success(
      <div className="flex flex-col">
        {data}
        {autoAssign && (
          <button
            className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
            onClick={() => goToChat()}
          >
            Ir al chat
          </button>
        )}
        {undoAction}
      </div>,
      {
        autoClose: 2000,
      }
    );
  };

  const getUndoAction = (
    action: string,
    autoAssign: boolean,
    agentTransfer: string
  ) => {
    if (context === "agent") {
      return (
        <button
          className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
          onClick={() => actionChat("transfer_chats_to_agent", true, "", true)}
        >
          Deshacer
        </button>
      );
    } else {
      const area = localStorage.getItem("currentArea");

      if (area === "WAITING AGENT") {
        switch (action) {
          case "archive_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() =>
                  actionChat("unarchive_chats", autoAssign, agentTransfer)
                }
              >
                Deshacer
              </button>
            );
          case "transfer_chats_to_agent":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("dessasign_agent_from_chats")}
              >
                Deshacer
              </button>
            );
          case "block_chats":
            return (
              <button
                className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
                onClick={() => actionChat("unblock_chats", true)}
              >
                Deshacer
              </button>
            );
          default:
            return null;
        }
      } else if (area === "ARCHIVED") {
        if (
          action === "unarchive_chats" ||
          action === "transfer_chats_to_agent" ||
          action === "block_chats"
        ) {
          return (
            <button
              className="mt-2 bg-[#0daa96] px-2 py-1 text-white"
              onClick={() => actionChat("archive_chats")}
            >
              Deshacer
            </button>
          );
        }
      }
    }
  };

  const handlePostAction = (action: string) => {
    const actionsThatResetClientUser = [
      "mark_chats_as_unread",
      "archive_chats",
      "dessasign_agent_from_chats",
      "block_chats",
      "transfer_chats_to_agent",
    ];

    if (actionsThatResetClientUser.includes(action)) {
      setClientUser({});
    } else {
      handleRefetch();
    }
  };

  return (
    <>
      {checked.length > 0 && (
        <div className={`selectionHeader`}>
          <div className="containerBtn">
            <button
              className="selectAll"
              onClick={() => selectAll()}
              title="Seleccionar todos"
            >
              <AiOutlineCheckSquare />
            </button>
            <button
              className="closeSelection"
              onClick={() => {
                setFilterSelection(false);
                setChecked([]);
              }}
            >
              <BsXLg />
            </button>
          </div>
          <p>{checked.length} seleccionados</p>
          {checked.length > 0 && (
            <button
              onClick={() => setopenSlide(!openSlide)}
              className="dropdownBtn"
              title="Acciones"
            >
              <AiOutlineArrowRight />
              {openSlide && (
                <>
                  <div
                    ref={dropdown}
                    className="dropdownContainer bg-[#121827]"
                  >
                    {context === "agent" ? (
                      <>
                        <button
                          onClick={() =>
                            actionChat("dessasign_agent_from_chats")
                          }
                          className="flex gap-2 items-center "
                        >
                          <BsPersonDash
                            style={{ color: "#ffffff", fontSize: 16 }}
                          />{" "}
                          Desasignarme chats
                        </button>
                        <button
                          onClick={() => actionChat("mark_chats_as_read")}
                          className="flex gap-2 items-center "
                        >
                          <MdMarkChatUnread
                            style={{ color: "#ffffff", fontSize: 16 }}
                          />
                          Marcar como leidos
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          actionChat("transfer_chats_to_agent", true)
                        }
                        className="flex gap-2 items-center"
                      >
                        <BsPersonDash
                          style={{ color: "#ffffff", fontSize: 16 }}
                        />{" "}
                        Asignarme chats
                      </button>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        getAgents();
                      }}
                      className="flex gap-2 items-center"
                    >
                      <BiTransfer style={{ color: "#ffffff", fontSize: 16 }} />
                      Transferir a agente
                    </button>
                    {agentDropdown && (
                      <div
                        ref={dropdownAgent}
                        className="dropdownContainer !w-[100px] border-white border !min-w-[200px] left-[50px] top-[140px] bg-[#121827] max-h-[200px] overflow-y-auto overflow-x-hidden"
                      >
                        {agentsList.map((agent) => (
                          <button
                            className="hover:text-[#20cab4]"
                            onClick={() =>
                              actionChat(
                                "transfer_chats_to_agent",
                                false,
                                /* @ts-ignore */
                                agent.email
                              )
                            }
                          >
                            {/* @ts-ignore */}
                            {agent.nombre}
                          </button>
                        ))}
                      </div>
                    )}
                    <button
                      onClick={() => actionChat("archive_chats")}
                      className="flex gap-2 items-center"
                    >
                      <FiArchive style={{ fontSize: 16 }} />
                      Archivar chats
                    </button>
                  </div>
                </>
              )}
            </button>
          )}
        </div>
      )}

      <div
        className={`container-messages w-full flex flex-col overflow-y-auto px-3`}
      >
        <OpenModal
          content={`¿Está seguro que desea dejar ${checked.length} chats?`}
          value="leaveSelected"
          btnValue="dejar"
          fn={handleLeaveMultipleChats.mutate}
        />
        <OpenModal btnValue="dejarlos" value="" />
        {isLoading
          ? Array.from({ length: 7 }).map((_, i) => <Skeletion key={i} />)
          : sortedConversations.map(
              (preview: PreviewInterface, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {preview?.last_message?.content && (
                      <div
                        className={`conversation-box 
                      w-full flex justify-between items-start px-3 py-1.5 cursor-pointer
                      border-2 border-[#1A2238] rounded-xl my-2
                      ${
                        clientUser.conversation_id !== preview.chat_id
                          ? ""
                          : "bg-[#121827]"
                      }
                      `}
                        key={index}
                        onClick={() => {
                          setInboxToggle(true);
                          if (isBroaderPreview === true) {
                            localStorage.setItem("is_broader_preview", "true");
                          } else {
                            localStorage.setItem("is_broader_preview", "false");
                          }
                          if (clientUser.conversation_id !== preview.chat_id) {
                            handleRefetch();
                            setUser(preview.chat_id);
                            //@ts-ignore
                            setClientUser({
                              name: preview.client.name,
                              img: preview.client.photo,
                              conversation_id: preview.chat_id,
                              phone_number: preview.client.phone_number,
                              tags: preview.tags,
                              products: preview.products,
                              sales: preview.sales,
                            });
                          }
                        }}
                      >
                        <Conversation
                          starred={preview.starred}
                          key={index}
                          time_left={preview.time_left}
                          name={preview.client.name}
                          phoneNumber={preview.client.phone_number}
                          lastMessage={preview.last_message.content}
                          lastMessageTimestamp={preview.last_message.datetime}
                          photo={preview.business.photo}
                          conversation_id={preview.chat_id}
                          checked={checked}
                          setChecked={setChecked}
                          checkConversation={checkConversations}
                          filter={filter}
                          // isChatSeen={isChatSeenMap[preview.chat_id] || preview.is_read_status}
                          isChatSeen={preview.is_read_status}
                          agentFullName={agent.fullName}
                          agentAssigned={
                            preview.agent_id !== null ? preview.agent_id : null
                          }
                          tags={preview.tags}
                          isSelectChatOpen={isSelectChatOpen}
                          handleRefetch={handleRefetch}
                          setUser={setUser}
                          isBroaderPreview={isBroaderPreview}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              }
            )}

        {isPreviousData ? (
          <div ref={skeletonRef}>
            {Array.from({ length: 10 }).map((_, i) => (
              <Skeletion key={i} />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};
