import React, { useState } from 'react';
import { IoIosSearch } from 'react-icons/io';

function ManagerSearchBar({ setManagerSection, folders, setFilteredFolders, setIsSearching, getAllFiles }: any) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value !== '') {
        setIsSearching(true);
      setManagerSection('Todos'); // Cambia a la sección 'Todos' automáticamente al buscar
      filterFolders(value);
    } else {
        setIsSearching(false);
    }
  };

  const filterFolders = (searchTerm: string) => {
    console.log(searchTerm);
    
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = getAllFiles(folders).filter((item: any) => {
        if ((item.name).toLowerCase().includes(lowercasedFilter)) {
            return item;
        }
    });

    console.log(filteredData);
    
  
    setFilteredFolders(filteredData); // Pasamos los resultados filtrados al componente padre
  };
  
  const handleFilteredFolders = (filteredData:any) => {
    setFilteredFolders(filteredData);
    setIsSearching(filteredData.length > 0);
};

  return (
    <div className='border-2 items-center justify-start pl-2 px-2 py-1 flex rounded-lg mb-6 border-gray-400 bg-[#1b2237]  w-full h-14'>
        <IoIosSearch className='text-2xl text-gray-400'/>
        <input 
          type="text" 
          className="w-full h-full p-2 text-lg focus:outline-none border-0 text-gray-400 bg-[#1b2237]" 
          placeholder="Buscar archivo..."
          value={searchTerm}
          onChange={handleSearchChange} 
        />
    </div>
  );
}

export default ManagerSearchBar;
