import axios from "axios";

export const addSale = async (company, newSale) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/sale`, newSale);
        if (response.status !== 200) {
            throw new Error('Error al añadir la venta');
        }
    }
    catch (error) {
        if (error instanceof Error) {
            console.log(error.message, "Catch de añadir venta.");
        } else {
            console.log("Catch de añadir venta.");
        }
    }
}

export const updateSale = async (company, saleId, updatedSale) => {
    try {
        const { agent_id, created_at, _id, ...saleDataForUpdate } = updatedSale;
        console.log(saleDataForUpdate);
        const response = await axios.put(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/sale/${saleId}`, saleDataForUpdate);
        if (response.status !== 200) {
            throw new Error('Error al actualizar la venta');
        }
    }
    catch (error) {
        if (error instanceof Error) {
            console.log(error.message, "Catch de actualizar venta.");
        } else {
            console.log("Catch de actualizar venta.");
        }
    }
}

export const deleteSale = async (company, saleId, agentId, chatId) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_V3}/chat_workspace/${company}/agent_workspace/sale/${saleId}`, {
                data: {
                  agent_id: agentId,
                  chat_id: chatId,
                },
                headers: {
                  'Content-Type': 'application/json'
                }
        });
        if (response.status !== 200) {
            throw new Error('Error al eliminar la venta');
        }
    }
    catch (error) {
        if (error instanceof Error) {
            console.log(error.message, "Catch de eliminar venta.");
        } else {
            console.log("Catch de eliminar venta.");
        }
    }
}