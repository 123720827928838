import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineFileUpload } from 'react-icons/md'
import { AiFillAudio } from 'react-icons/ai'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { BsFillRecordFill, BsFillPauseFill, BsXCircleFill,  } from 'react-icons/bs'
import CustomAudioPlayer from './CustomAudioPlayer'
// usare tostify
import { toast } from 'react-toastify';


function AddFile({ setFileManager, handleFileChange, handleSubmit, name, setName, handleFolderSelection, folders, newFolder, setNewFolderName, starred, setStarred, file, audioURL, setAudioURL}) {
    const mediaRecorderRef = useRef(null);
    const [message, setMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const audioPlayerRef = useRef(null);
    const [recordingError, setRecordingError] = useState(null);

    const loadRecorderScripts = async () => {
        if (typeof window.OpusMediaRecorder === 'undefined') {
            try {
                await loadScript('https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js');
                await loadScript('https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js');
            } catch (error) {
                console.error("Error loading OpusMediaRecorder scripts:", error);
            }
        }
    };

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };

    useEffect(() => {
        loadRecorderScripts();
        return () => {
            //@ts-ignore
            if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
                //@ts-ignore
                mediaRecorderRef.current.stop();
            }
        };
    }, []);

    const startRecording = async () => {
        //@ts-ignore
        if (!window.OpusMediaRecorder) {
            console.error("OpusMediaRecorder is not loaded yet.");
            return;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const options = { mimeType: 'audio/ogg; codecs=opus' };
            const workerOptions = {
                OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
                WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
            };
            const recorder = new window.OpusMediaRecorder(stream, options, workerOptions);
            recorder.ondataavailable = (e) => {
                const audioBlob = new Blob([e.data], { type: 'audio/ogg; codecs=opus' });
                setAudioURL(URL.createObjectURL(audioBlob));
            };
            recorder.start();
            mediaRecorderRef.current = recorder;
            setIsRecording(true);
            setMessage('');
        } catch (error) {
            console.error('Error accessing media devices:', error);
            setMessage('Recording not supported by this browser.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const resetAudio = () => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
        }
        setAudioURL('');
        setMessage('');
        setIsRecording(false);
    };


  return (

                <div className=' bg-[#333c5b] rounded-2xl modal-styles '>
                    <div className='flex w-full justify-around '>
                        <div className='flex flex-col items-center cursor-pointer justify-center'>
                            <label htmlFor="file-upload" className={`text-[100px] rounded-full p-3 cursor-pointer ${file ? 'bg-green-500 text-white' : 'bg-[#57607f] text-[#212944]'}`}>
                                <MdOutlineFileUpload />
                            </label>
                            <input id="file-upload" type="file" onChange={handleFileChange} className="hidden cursor-pointer"/>
                            <button type="button" className=' text-white p-2 rounded-lg'>
                                Subir Archivo
                            </button>
                        </div>
                        <div className='h-[100px] bg-[#212944] w-[2px]'></div>
                        <div className='flex flex-col items-center cursor-pointer justify-center'>
                            {
                                isRecording ? (
                                    <div className="recording-audio-indicator flex flex-col items-center">
                                        <AiFillAudio className="text-[125px] p-2 rounded-full bg-red-500 text-[#212944]" />
                                        <button className='text-[#57607f] p-2 rounded-lg flex items-center justify-center' onClick={stopRecording}>
                                            <BsFillPauseFill className="text-[20px]" />
                                        </button>
                                    </div>
                                ) : audioURL ? (
                                    <div className="flex flex-col items-center w-full">
                                        <CustomAudioPlayer src={audioURL} />
                                        <button className='text-[#57607f] p-2 rounded-lg flex items-center justify-center' onClick={resetAudio}>
                                            <BsXCircleFill className="text-[20px]" />
                                        </button>
                                    </div>


                                ) : (
                                    <div className="flex flex-col items-center">
                                        <AiFillAudio onClick={startRecording} className={`text-[125px] p-2 rounded-full ${isRecording ? 'bg-red-500' : 'bg-[#57607f]'} text-[#212944]`}/>
                                        <button type="button" className='text-white p-2 rounded-lg flex items-center justify-center' onClick={startRecording}>
                                            Grabar Audio
                                        </button>
                                    </div>
                                )
                            }
                           
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-start h-full'>
                        <form className='flex flex-col items-center w-full px-5' onSubmit={handleSubmit}>
                            <label className='text-gray-400 w-full'>Nombre</label>
                            <input 
                                type="text" 
                                value={name}
                                accept="video/mp4, application/pdf, image/jpeg, image/png"
                                onChange={(e) => setName(e.target.value)}
                                className='text-gray-400 border-2 border-gray-400 bg-[#1b2237] rounded-lg focus:border-gray-400 focus:ring-0 w-full mb-4'
                                placeholder='Nombre del archivo...'
                            />
                            <label className='text-gray-400 w-full'>Ubicación</label>
                            <div className='relative w-full'>
                                <select 
                                    className='text-gray-400 border-2 border-gray-400 bg-[#1b2237] rounded-lg focus:border-gray-400 focus:ring-0 w-full mb-4'
                                    onChange={handleFolderSelection}
                                >
                                    <option value="default">Selecciona una carpeta...</option>
                                    {Object.values(folders).map((folder) => (
                                        <option key={folder.name} value={folder.name}>{folder.name}</option>
                                    ))}
                                    <option value="new-folder">Crear carpeta...</option>
                                </select>
                                <div className='flex items-center justify-center'>
                                    {newFolder && (
                                        <div className="relative w-full flex items-center gap-2">
                                            <input 
                                                type="text" 
                                                placeholder='Nombre de la nueva carpeta...'
                                                className='w-[70%] p-2 text-gray-400 bg-[#1b2237] border-2 border-gray-400 rounded-lg focus:border-gray-400 focus:ring-0'
                                                onChange={(e) => setNewFolderName(e.target.value)}
                                            />
                                        </div>
                                    )}
                                    <div className='flex items-start justify-center'>
                                        {starred ? (
                                            <button onClick={(e) => {e.preventDefault(); setStarred(false)}} className='flex items-start justify-start gap-2 '>
                                                <FaStar className='text-2xl text-yellow-300'/><p>Quitar de favoritos</p>
                                            </button>
                                        ) : (
                                            <button onClick={(e) => {e.preventDefault(); setStarred(true)}} className='flex items-start justify-start gap-2 '>
                                                <FaRegStar className='text-2xl'/><p>Destacar</p>
                                            </button>
                                        )}
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className='flex items-center justify-center gap-5 w-full absolute bottom-6 py-4'>
                                <button onClick={() => setFileManager(false)} className='bg-[#ff5757] text-white py-3 px-10 rounded-lg'>
                                    Cancelar
                                </button>
                                <button type='submit' className='bg-[#20cab3] text-white py-3 px-10 rounded-lg'>
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
    )
}

export default AddFile