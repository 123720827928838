import React from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import { FaEye, FaRegEdit, FaRegStar, FaStar } from 'react-icons/fa'
import { IoMdSend } from 'react-icons/io'
import { MdDelete } from 'react-icons/md'


function Folder({ folders, managerSection, setManagerSection, mediaDots, toggleMediaDots, setFastFav, fastFav, handleAddFastFavorite, handleRemoveFastFavorite, handleDeleteFileFromFolder, handleFileSending}: any) {
  return (
    <>
        { 
            folders[managerSection]  ? (
                folders[managerSection].files.length === 0 ? (
                    <div className='p-2'>No hay archivos en la carpeta.</div>
                ) : (
                folders[managerSection].files.map((file: any) => (
                    <div key={file.name} className='w-[150px] h-[150px] relative bg-gray-300 rounded-xl z-[10]'>
                        <HiDotsVertical onClick={() => {
                            toggleMediaDots(file.name)
                            setFastFav(file.favorite)
                            }} className='text-xl cursor-pointer absolute top-1 right-1 text-black z-[12]'/>
                        {
                            file.type === "audio" && (
                                // verificar el audio que es una direccion https
                                <audio src={file.url} controls className='w-full h-full rounded-xl' />
                            )
                        }
                        {
                            file.type === "image" && (
                                <img src={file.url} alt={file.name} className='w-full h-full rounded-xl' />
                            )
                        }
                        {
                            file.type === "video" && (
                                <video src={file.url} controls className='w-full h-full rounded-xl' />
                            )
                        }
                        {
                            file.type == "file" && (
                                // debe mostrar un file que es un https de pdf o docs
                                <iframe
                                src={file.url}
                                className="w-full h-full rounded-xl"  // Ajusta el ancho (w-full) y la altura (h-64) según tus necesidades
                                title="Vista previa del documento"
                                >
                                </iframe>
                            )
                        }
                        {
                            mediaDots === file.name && (
                                <div className='absolute w-[150px] media-dots-menu left-5 bottom-[-25px] bg-[#212944] border-2 border-gray-400 p-2 rounded-lg z-[15]'>
                                    <ul className='space-y-2'>
                                        <a href={file.url} target='_blank' className='text-gray-400 flex justify-start items-center'><FaEye className='text-lg mr-1'></FaEye>Ver</a>
                                        {/* <li className='text-gray-400 flex justify-start items-center'><FaRegEdit className='text-lg mr-1'></FaRegEdit>Editar</li> */}
                                        <li className='text-gray-400 flex justify-start items-center cursor-pointer' onClick={() => handleFileSending(file)}><IoMdSend className='text-lg mr-1'></IoMdSend> Enviar</li>
                                        {
                                            fastFav ? (
                                                <a onClick={() => handleRemoveFastFavorite(managerSection, file.name)} className='text-gray-400 cursor-pointer flex justify-start items-center'>
                                                    <FaStar className='text-lg mr-1 text-yellow-300'></FaStar>
                                                    Quitar de Favoritos
                                                </a>
                                            ) : (
                                                <a onClick={() => handleAddFastFavorite(managerSection, file.name)} className='text-gray-400 flex cursor-pointer justify-start items-center'>
                                                    <FaRegStar className='text-lg mr-1'></FaRegStar>
                                                    Añadir a Favoritos
                                                </a>
                                            )
                                        }
                                        <li className='text-gray-400 cursor-pointer flex justify-start items-center' onClick={() => handleDeleteFileFromFolder(managerSection, file.name)}><MdDelete className='text-lg mr-1'></MdDelete>Eliminar</li>
                                    </ul>
                                </div>
                            )
                        }
                        <p className='text-gray-400 text-sm text-center mt-2'>{file.name}</p>
                    </div>
                )))
            ) : (
                setManagerSection("Favoritos")
            )

            
        }

    </>
  )
}

export default Folder