import { useState , SyntheticEvent, FC, useEffect, useContext} from 'react';
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import AgentAccordion from '../components/AgentAccordion/AgentAccordion'
import { addAgent, agents, deleteAgent } from '../services/Agents.services';
import './Config.css'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import userContext from "../utils/userContext";
import { context } from "../interfaces";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast, ToastContainer } from "react-toastify";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FORM_AGENT = {
	nombre: '',
	email: '',
	is_admin: false
}

const Admin: React.FC = () => {
	const params = useParams()
	const { agent } = useContext(userContext)  as context;;
    const [agentList, setAgentList] = useState([])

	const [loading, setLoading] = useState(true)
	const [open, setOpen] = useState(false);
	const [addAgentForm, setAddAgentForm] = useState(FORM_AGENT)
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
  
    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
		sendForm()
	}

	const sendForm = async () => {
		setLoading(true)
		try {
			const data = await addAgent(addAgentForm, agent.company)
			setLoading(false)
			handleClose()
			toast.success(data.message, {
				autoClose: 2000,
			})
			setAddAgentForm(FORM_AGENT)
		}
		catch {
			console.log('err')
			setLoading(false)
		}
	}
	//@ts-ignore
	function deleteAgentFromList(arr, agent) {
		// Utilizamos la función filter para crear un nuevo array sin el objeto que contiene el email
		//@ts-ignore
		const newArr = arr.filter(obj => obj.email !== agent);

		return newArr;
	  }

	const getAgents = async () => {
        try {
            const data = await agents(agent.company)
            console.log('agents: ', data)
			console.log('email: ', agent.email)
			//@ts-ignore
            setAgentList(deleteAgentFromList(data, agent.email))
        }
        catch (e){
          console.log('error en agents')
        }
        finally {
        }
    }

	const removeAgent = async (id:string) => {
		setLoading(true)
        try {
            const data = await deleteAgent(agent.company, id)
            if(data) {
                toast.success("Agente eliminado con exito", {
                    autoClose: 2000,
                })
				setLoading(false)
            }
        }
        catch (e){
		setLoading(false)
          toast.error('error eliminando agente', {
            autoClose: 2000,
        })
        }
        finally {
       
        }
    }

    const handleChange = (e:any) => {
		setAddAgentForm({...addAgentForm, [e.target.name] : e.target.value})
	}

    const handleChangeSwitch = (e:any) => {
		setAddAgentForm({...addAgentForm, is_admin : !addAgentForm.is_admin})
	}

	useEffect(() => {
		getAgents()
	}, [loading])

    return (
			<div className="min-h-screen w-screen font-sans items-center justify-center text-black bg-white">
				<NavBar />
				<div style={{padding: '25px 35px'}}>
					<div className="title-config">
						<h2>Gestor de Usuarios</h2>
					</div>
					<div className='w-full flex items-center justify-center'>
					<button onClick={handleOpen} className='button-admin'>REGISTRAR</button>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<ValidatorForm
								className='flex items-center flex-col'
								// ref="form"
								// onSubmit={this.handleSubmit}
								//@ts-ignore
								onSubmit={handleSubmit}
								onError={errors => console.log(errors)}
							>
								<div className='mb-4 w-full'>
									<TextValidator 
										id="nombre"
										name="nombre"
										label="Nombre"
										className='mb-5 w-full'
										onChange={(e) => handleChange(e)}
										validators={['required']}
										value={addAgentForm.nombre}
										errorMessages={['Este campo es obligatorio']}

									/>
								</div>
								<div className='mb-4 w-full'>
									<TextValidator 
										id="email"
										name="email"
										label="Correo Electronico"
										className='mb-3 w-full'
										onChange={(e) => handleChange(e)}
										value={addAgentForm.email}
										validators={['required', 'isEmail']}
										errorMessages={['Este campo es obligatorio', 'email no valido']}

									/>
								</div>
								<div className='flex justify-between w-full items-center'>
									<span>Es administrador: </span>
									<Switch
										defaultChecked 
										checked={addAgentForm.is_admin}
										onChange={handleChangeSwitch}
										className='mb-5'
									/>
								</div>
								<button className="bg-[#3ee8cf] w-full text-black p-3" type="submit">Confirmar</button>
							</ValidatorForm>
						</Box>
					</Modal>
					</div>
					<div className="title-config">
						<h2>Agentes disponibles</h2>
					</div>
					<div className='flex justify-center items-center'>
						<AgentAccordion agents={agentList} removeAgent={removeAgent}/>
					</div>
				</div>
				<ToastContainer 
					position="top-right"
					autoClose={3000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
    )
};

export default Admin;