import axios from 'axios';

const sendAudioMultimedia = async (formData, company) => {
    const res = await axios.post (`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/audio`, formData)
    return res
}

const sendImageMultimedia = async (formData, company) => {
    const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/image`, formData);
    return response;
}


// Media Manager Queries

const getFolders = async (company) => {
    const response = await axios.get(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/get_folders`);
    console.log(response.data);
    return response;
}

// Folders

const createFolder = async (company, folder_name) => {
    const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/create_folder`, {
        folder_name: folder_name
    });
    return response;
}

const deleteFolder = async (company, folder_name) => {
    console.log(company, folder_name);
    const response = await axios.delete(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/delete_folder`, {
        data: {
            folder_name: folder_name
        },
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response;
}

const renameFolder = async (company, folder_name, new_folder_name) => {
    const response = await axios.put(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/edit_folder`, {
        folder_name: folder_name,
        new_folder_name: new_folder_name
    });
    return response;
}

// Files

const addFileToFolder = async (company, folder_name, file) => {
    const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/add_file_to_folder`, {
        folder_name: folder_name,
        file_data: file
    });
    return response;
}

const deleteFileFromFolder = (company, folder_name, file_name) => {
    const response = axios.delete(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/delete_file_from_folder`, {
        data: {
            folder_name: folder_name,
            file_data: {
                name: file_name
            }
        },
        headers: {
            'Content-Type': 'application/json'
        } 
    });
    return response;
}

const editFileFromFolder = async (company, folder_name, file_name, new_file_data) => {
    const response = await axios.put(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/edit_file_from_folder`, {
        folder_name: folder_name,
        file_name: file_name,
        new_file_data: new_file_data
    });
    return response;
}

// Favorites

const addFileToFavorite = async (company, folder_name, file_name) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/add_file_to_favorite`, {
            folder_name: folder_name,
            file_name: file_name
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error adding file to favorites:', error.response ? error.response.data : error);
        throw error;  // Optionally re-throw the error if you want calling code to handle it
    }
}


const removeFileFromFavorite = async (company, folder_name, file_name) => {
    console.log(company, folder_name, file_name);
    const response = await axios.delete(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/delete_file_from_favorite`, {
        data: JSON.stringify({
            folder_name: folder_name,
            file_name: file_name
        }),
        headers: {
            'Content-Type': 'application/json',
            "accept": "application/json"
        }
    });
    return response.data;
}

const getFavoriteFiles = async (company) => {
    const response = await axios.get(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/get_favorite_files`);
    return response;
}

// urls y ids

const getMediaUrl = async (company, file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/get_media_url`, formData);
    return response;
}

const getMediaId = async (company, file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(`${process.env.REACT_APP_API_NEW_URL}/multimedia/${company}/get_media_id`, formData);
    return response;
}

// file sending

const handleAudioDownloading = async (file) => {
    try {
        const response = await fetch(file.url, {
            method: 'GET', // Especifica el método HTTP
            headers: {
                'Content-Type': 'audio/ogg', // Asegúrate de usar el tipo MIME correcto
                "mode": "no-cors"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.blob(); // Obtiene el archivo como un objeto blob
        const localUrl = URL.createObjectURL(data); // Crea una URL local para el objeto blob
        return localUrl;
    } catch (error) {
        console.log(file.url, file);
        console.error("Error descargando el archivo de audio: ", error);
        throw error; // Re-lanza el error para manejarlo en otra parte si es necesario
    }
}

const handleVideoDownloading = async (file) => {
    try {
        const response = await fetch(file.url, {
            method: 'GET', // Especifica el método HTTP
            headers: {
                'Content-Type': 'video/mp4', // Asegúrate de usar el tipo MIME correcto
                "mode": "no-cors"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.blob(); // Obtiene el archivo como un objeto blob
        const videoFile = new File([data], "downloadedVideo.mp4", {
            type: "video/mp4", // Asegúrate de establecer el tipo MIME correcto
            lastModified: new Date() // Puedes ajustar la fecha de modificación si es necesario
        });

        return videoFile;
    } catch (error) {
        console.error("Error descargando el archivo de video: ", error);
        throw error; // Re-lanza el error para manejarlo en otra parte si es necesario
    }
}



export {sendAudioMultimedia, getMediaId, handleAudioDownloading, handleVideoDownloading, sendImageMultimedia, getMediaUrl, addFileToFavorite, removeFileFromFavorite, getFavoriteFiles, getFolders, createFolder, deleteFolder, renameFolder, addFileToFolder, deleteFileFromFolder, editFileFromFolder}