import React, { useState, useRef, useEffect } from 'react';
import { FaRegStar } from "react-icons/fa";
import { IoFolderOpenOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import "./FileManager.css";
import { AiFillAudio } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { getFolders, getFavoriteFiles, getMediaUrl, addFileToFolder, addFileToFavorite, removeFileFromFavorite, createFolder, deleteFolder, handleAudioDownloading, handleVideoDownloading } from '../../services/multimedia.service';
import ManagerSearchBar from './ManagerSearchBar';
import { IoMdSend } from "react-icons/io";
import { deleteFileFromFolder } from '../../services/multimedia.service';
import AddFile from './AddFile.jsx';
import Folder from './Folder';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function FileManagerComponent({ company, fileManager, setFileManager, audioPreview, setAudioPreview, filePreview, setFilePreview, fileDataPreview, setFileDataPreview }: any) {
    const fileManagerRef = useRef(null);
    const [managerSection, setManagerSection] = useState('Favoritos');
    const [starred, setStarred] = useState(false);
    const [newFolder, setNewFolder] = useState(false); // Nuevo estado para manejar la creación de carpetas
    const [mediaDots, setMediaDots] = useState(null);
    const [folders, setFolders] = useState([]); // Nuevo estado para manejar las carpetas
    const [favoriteFiles, setFavoriteFiles] = useState([]); // Nuevo estado para manejar los archivos favoritos
    const [loadingFolders, setLoadingFolders] = useState(true); // Nuevo estado para manejar la carga de las carpetas
    const [file, setFile] = useState(null);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [name, setName] = useState('');
    const [loadingFavoritesFolders, setLoadingFavoritesFolders] = useState(true); // Nuevo estado para manejar la carga de las carpetas favoritas
    const [newFolderName, setNewFolderName] = useState(''); // Estado para el nombre de la nueva carpeta
    const [selectedFolder, setSelectedFolder] = useState('default'); // Estado para la carpeta seleccionada
    const [fastFav, setFastFav] = useState(false);
    const [filteredFolders, setFilteredFolders] = useState([]); // Nuevo estado para manejar las carpetas filtradas
    const [isSearching, setIsSearching] = useState(false); // Nuevo estado para manejar la búsqueda de carpetas
    const [formSubmitting, setFormSubmitting] = useState(false); // Nuevo estado para manejar la carga del formulario
    const [audioURL, setAudioURL] = useState('');
    const [confirmDeleteFolder, setConfirmDeleteFolder] = useState(false);


    const toggleMediaDots = (fileId: null) => {
        if (mediaDots === fileId) {
            setMediaDots(null); // Si ya está abierto, ciérralo.
        } else {
            setMediaDots(fileId); // Si está cerrado, ábrelo para el archivo clicado.
        }
    };

    useEffect(() => {
        getFavoriteFiles(company).then((response: any) => {
            console.log(response.data);
            setFavoriteFiles(response.data);
        })
        .catch((error: any) => {
            console.error(error);
        })
        .finally(() => {
            setLoadingFavoritesFolders(false);
        });

        getFolders(company).then((response: any) => {
            console.log(response.data);
            setFolders(response.data);
        })
        .catch((error: any) => {
            console.error(error);
        })
        .finally(() => {
            setLoadingFolders(false);
        });
    }, []);

    const reFetchFolders = () => {
        setLoadingFavoritesFolders(true);
        getFavoriteFiles(company).then((response: any) => {
            console.log(response.data);
            setFavoriteFiles(response.data);
        }
        )
        .catch((error: any) => {
            console.error(error);
        })
        .finally(() => {
            setLoadingFavoritesFolders(false);
        });

        setLoadingFolders(true);
        getFolders(company).then((response: any) => {
            console.log(response.data);
            setFolders(response.data);
            setFilteredFolders(response.data);
        })
        .catch((error: any) => {
            console.error(error);
        })
        .finally(() => {
            setLoadingFolders(false);
        });
    }

    function getAllFiles(data: any) {
        //@ts-ignore
        let allFiles = [];
    
        // Verificamos que 'data' es un objeto y tiene propiedades
        if (data && typeof data === 'object') {
            for (const folderName in data) {
                // Asegurarse que la propiedad realmente pertenece al objeto 'data'
                if (data.hasOwnProperty(folderName)) {
                    const files = data[folderName].files;
    
                    // Verificamos que 'files' sea un array antes de procesar
                    if (Array.isArray(files)) {
                        // Agregamos los archivos al array 'allFiles' con la información de su carpeta
                                //@ts-ignore
                        allFiles = allFiles.concat(files.map(file => {
                            return {
                                ...file,  // Copia todas las propiedades de 'file'
                                folder: folderName  // Añade el nombre de la carpeta como una propiedad adicional
                            };
                        }));
                    }
                }
            }
        }
        
        return allFiles;
    }
    
    

    useEffect(() => {
        function handleClickOutside(event: any) {
            //@ts-ignore
            if (fileManagerRef.current && !fileManagerRef.current.contains(event.target)) {
                setFileManager(false); // Cerrar el gestor si se clickea fuera
            }
    
            // Cerrar mediaDots si se clickea fuera
            // Supongamos que tienes una referencia al contenedor de mediaDots, si no la tienes, deberías crear una
            //@ts-ignore
            if (!event.target.closest('.media-dots-menu')) {
                setMediaDots(null);
            }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setFileManager]);
    

    const handleFolderSelection = (event: any) => {
        const { value } = event.target;
        if (value === 'new-folder') {
            setNewFolder(true);
            setNewFolderName(''); // Resetear el nombre de la nueva carpeta cuando se selecciona esta opción
        } else {
            setNewFolder(false);
            setSelectedFolder(value); // Actualizar el nombre de la carpeta seleccionada
        }
    };

    const handleNewFolderNameChange = (event: any) => {
        setNewFolderName(event.target.value);
    };
    
    

    const handleFileChange = (event: any) => {
        if (event.target.files[0]) {
            setFile(event.target.files[0]);
            setIsFileSelected(true);
        }
    };

    const getFileType = (fileName: any) => {
        const ext = fileName.toLowerCase().split('.').pop();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
        const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];
    
        if (imageExtensions.includes(ext)) {
            return 'image';
        } else if (videoExtensions.includes(ext)) {
            return 'video';
        } else {
            return 'file';
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setFormSubmitting(true);

        try {
            const folderName = newFolder ? newFolderName : selectedFolder; // Usar el nombre de la nueva carpeta si estamos en modo 'newFolder'

            if (newFolder) {
                console.log("Creating new folder:", newFolderName);
                
                createFolder(company, newFolderName).then(async (response) => {
                    console.log("Folder created:", response.data);

                    if (audioURL !== '') {
                        const audioBlob = await fetch(audioURL).then(r => r.blob());
                        const now = new Date();
                        const fileName = now.toISOString().replace(/[\-\:\.T]/g, '').slice(0, 14) + '.ogg';
                        const audioFile = new File([audioBlob], fileName, { type: "audio/ogg; codecs=opus" });

                        getMediaUrl(company, audioFile).then((response) => {
                            //@ts-ignore
                            const fileType = getFileType(audioFile.name);
                            const formData = {
                                name,
                                url: response.data.url,
                                type: "audio",
                                favorite: starred,
                            };
                            console.log(formData);
                            addFileToFolder(company, folderName, formData).then((response) => {
                                console.log("File added:", response.data);
                                // Reset the form here
                                setName('');
                                setFile(null);
                                setAudioURL("")
                                setIsFileSelected(false);
                                setStarred(false);
                                setNewFolder(false);
                                setNewFolderName('');
                                reFetchFolders()
                
                            })
                            .catch((error) => {
                                console.error("Error adding file:", error);
                            });
                        })
                    } else {
                        getMediaUrl(company, file).then((response) => {
                            //@ts-ignore
                            const fileType = getFileType(file.name);
                            const formData = {
                                name,
                                url: response.data.url,
                                type: fileType,
                                favorite: starred,
                            };
                            console.log(formData);
                            addFileToFolder(company, folderName, formData).then((response) => {
                                console.log("File added:", response.data);
                                // Reset the form here
                                setName('');
                                setFile(null);
                                setIsFileSelected(false);
                                setStarred(false);
                                setNewFolder(false);
                                setNewFolderName('');
                                reFetchFolders()
                
                            })
                            .catch((error) => {
                                console.error("Error adding file:", error);
                            });
                        })
                    }
                }
                )
                .catch((error) => {
                    toast.error("Error creating folder");
                    console.error("Error creating folder:", error);
                });
            } else {

                if (audioURL !== '') {
                    const audioBlob = await fetch(audioURL).then(r => r.blob());
                    const now = new Date();
                    const fileName = now.toISOString().replace(/[\-\:\.T]/g, '').slice(0, 14) + '.ogg';
                    const audioFile = new File([audioBlob], fileName, { type: "audio/ogg; codecs=opus" });

                    getMediaUrl(company, audioFile).then((response) => {
                        //@ts-ignore
                        const fileType = getFileType(audioFile.name);
                        const formData = {
                            name,
                            url: response.data.url,
                            type: "audio",
                            favorite: starred,
                        };
                        console.log(formData);
                        addFileToFolder(company, folderName, formData).then((response) => {
                            console.log("File added:", response.data);
                            // Reset the form here
                            setName('');
                            setFile(null);
                            setAudioURL("")
                            setIsFileSelected(false);
                            setStarred(false);
                            setNewFolder(false);
                            setNewFolderName('');
                            reFetchFolders()
            
                        })
                        .catch((error) => {
                            console.error("Error adding file:", error);
                        });
                    })
                } else {
                    getMediaUrl(company, file).then((response) => {
                        //@ts-ignore
                        const fileType = getFileType(file.name);
                        const formData = {
                            name,
                            url: response.data.url,
                            type: fileType,
                            favorite: starred,
                        };
                        console.log(formData);
                
                        addFileToFolder(company, folderName, formData).then((response) => {
                            console.log("File added:", response.data);
                            // Reset the form here
                            setName('');
                            setFile(null);
                            setIsFileSelected(false);
                            setStarred(false);
                            setNewFolder(false);
                            setNewFolderName('');
                            reFetchFolders()
            
                        })
                        .catch((error) => {
                            console.error("Error adding file:", error);
                        });
                    })
                    .catch((error) => {
                        toast.error("Error getting media URL");
                        console.error("Error getting media URL:", error);
                    });
                }
            }


        } catch (error) {
            console.error(error);
        } finally {
            setFormSubmitting(false);
        }
    };

    const handleDeleteFileFromFolder = (folderName: any, fileName: any) => {
        deleteFileFromFolder(company, folderName, fileName).then((response) => {
            console.log("File deleted:", response.data);
            reFetchFolders()
        })
        .catch((error) => {
            console.error("Error deleting file:", error);
        });
    }

    const handleAddFastFavorite = (folderName: any, fileName: any) => {
        addFileToFavorite(company, folderName, fileName).then((response) => {
            console.log("File added to favorites:", response.data);
            reFetchFolders()
            setFastFav(true)
        })
        .catch((error) => {
            console.error("Error adding file to favorites:", error);
        });
    }

    const handleRemoveFastFavorite = (folderName: any, fileName: any) => {
        removeFileFromFavorite(company, folderName, fileName).then((response) => {
            console.log("File removed from favorites:", response.data);
            reFetchFolders()
            setFastFav(false)
        })
        .catch((error) => {
            console.error("Error removing file from favorites:", error);
        });
    }

    function encontrarCarpeta(directorio: any, nombreArchivo: any) {
        // Recorremos cada propiedad del objeto directorio
        for (let carpeta in directorio) {
            // Accedemos a la lista de archivos en la propiedad 'files'
            const archivos = directorio[carpeta].files;
            // Buscamos si algún archivo coincide con el nombreArchivo
            for (let archivo of archivos) {
                if (archivo.name === nombreArchivo) {
                    return directorio[carpeta].name;  // Retornamos el nombre de la carpeta
                }
            }
        }
        return false;  // Si no encontramos el archivo en ninguna carpeta
    }

    const handleDeleteFolder = (folderName: any) => {
        setConfirmDeleteFolder(true)
    }

    const finallyDeleteFolder = (folderName: any) => {
        deleteFolder(company, folderName).then((response) => {
            console.log("Folder deleted:", response.data);
            reFetchFolders()
            setConfirmDeleteFolder(false)
        })
        .catch((error) => {
            console.error("Error deleting folder:", error);
            reFetchFolders()
            setConfirmDeleteFolder(false)

        });
    }

    const handleFileSending = async (file: any) => {
        console.log(file);
    
        if (file.type === "audio") {
            try {
                // Usar la función handleAudioDownloading para obtener la URL local del archivo
                const localUrl = await handleAudioDownloading(file);
                setAudioPreview(localUrl); // Usar la URL local para el preview
                setFileManager(false); // Cerrar el file manager
            } catch (error: any) {
                console.error("Error descargando el archivo de audio: ", error.message);
            }
        }

        if (file.type === "image") {
            setFilePreview(true);
            setFileDataPreview(file);
            setFileManager(false);
        }

        if (file.type === "video") {
            try {
                // Usar la función handleVideoDownloading para obtener la URL local del archivo
                const archivoLocal = await handleVideoDownloading(file);
                setFilePreview(true);
                setFileDataPreview({file: archivoLocal, url: file.url, type: "video"}); // Usar la URL local para el preview
                setFileManager(false); // Cerrar el file manager
            }
            catch (error: any) {
                console.error("Error descargando el archivo de video: ", error.message);
            }
        }

        if (file.type === "file") {
            setFilePreview(true);
            setFileDataPreview(file);
            setFileManager(false);
        }
    
        console.log("paso");
    }
    

    return (
        
        fileManager && (
            <>
            <div ref={fileManagerRef} className="flex absolute z-[124214124124] items-center justify-center top-[12%] file-manager-styles bg-[#333c5b] text-gray-300 w-[50%] h-[70%] rounded-2xl">
                                    {
                                        confirmDeleteFolder && (
                                            <div className='absolute bg-gray-600 flex flex-col items-center jsutify-center rounded-lg p-5  w-[50%] h-[200px] z-[100]'>
                                                <p className='mb-2'>Junto con la carpeta se eliminaran los archivos dentro.</p>
                                                <p className='mb-2'>Estas seguro que quieres eliminarla?</p>
                                                <div className='flex gap-2 '>
                                                    <button className='px-8 py-2 rounded-lg ' onClick={() => setConfirmDeleteFolder(false)}><u>Cancelar</u></button>
                                                    <button className='px-8 py-2 rounded-lg bg-[#ff5757]' onClick={() => finallyDeleteFolder(managerSection)}>Eliminar</button>
                                                </div>
                                            </div>
                                        )
                                    }
                    <button onClick={() => setFileManager(false)} className="absolute top-0 right-0 p-2 m-2 text-white hover:text-red-500">
                        <IoIosClose className="text-2xl" />
                    </button>
                    <div className='flex flex-col w-[35%] h-full p-5'>
                        <ManagerSearchBar setManagerSection={setManagerSection} folders={folders} setFilteredFolders={setFilteredFolders} setIsSearching={setIsSearching} getAllFiles={getAllFiles}></ManagerSearchBar>
                        <ul className='space-y-4 overflow-y-auto'>
                            
                        <li onClick={() => setManagerSection("Favoritos")} className={`cursor-pointer p-2 flex items-center justify-start rounded-md ${managerSection === 'Favoritos' ? 'text-[#23b6a6]' : 'text-gray-400 hover:bg-gray-700'}`}>
                            <FaRegStar className={`inline-block text-2xl mr-2 ${managerSection === 'Favoritos' ? 'text-[#23b6a6]' : 'text-gray-400'}`} /> <p className='text-lg'>Favoritos</p>
                        </li>
                        {
                            !loadingFolders && Object.values(folders).map((folder: any) => (
                                <li key={folder.name} onClick={() => setManagerSection(folder.name)} className={`cursor-pointer p-2 flex items-center relative justify-start rounded-md ${managerSection === folder.name ? 'text-[#23b6a6]' : 'text-gray-400 hover:bg-gray-700'}`}>
                                    <IoFolderOpenOutline className={`inline-block text-2xl mr-2 ${managerSection === folder.name ? 'text-[#23b6a6]' : 'text-gray-400'}`} /> <p className='text-lg'>{folder.name}</p> <MdDelete onClick={() => handleDeleteFolder(folder.name)} className={` absolute right-0 ${managerSection === folder.name ? 'text-red-500' : 'text-gray-400 hover:bg-gray-700'}`}/> 
                                </li>
                            ))
                        }
                        <li onClick={() => setManagerSection("Todos")} className={`cursor-pointer p-2 flex items-center justify-start rounded-md ${managerSection === 'Todos' ? 'text-[#23b6a6]' : 'text-gray-400 hover:bg-gray-700'}`}>
                            <TbWorld className={`inline-block text-2xl mr-2 ${managerSection === 'Todos' ? 'text-[#23b6a6]' : 'text-gray-400'}`} /> <p className='text-lg'>Todos</p>
                        </li>

                            <li onClick={() => setManagerSection("Añadir Archivo")} className='cursor-pointer text-lg px-4 py-2 text-white bg-[#57607f] absolute bottom-4 left-16 flex items-center justify-start rounded-md'>
                                    <p>Añadir Archivo</p>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-5 rounded-2xl border-[1.5px]  w-[65%] h-full p-5 ">
                        <h2 className='mb-4 text-xl'>{managerSection}</h2>
                        {
                            managerSection !== 'Añadir Archivo' && (
                                <div className='w-[100%] bg-[#333c5b] min-h-[70%] max-h-full rounded-2xl modal-styles flex flex-wrap  gap-x-10 gap-y-10 overflow-y-auto'>

                                    

                                    {
                                        managerSection == "Todos" && (
                                            //@ts-ignore
                                            isSearching ? (
                                                filteredFolders.map((file: any) => (
                                                    <div key={file.name} className='w-[150px] h-[150px] relative file-item bg-gray-300 rounded-xl z-[10]'>
                                                    {
                                                            file.type === "audio" && (
                                                                // verificar el audio que es una direccion https
                                                                <audio src={file.url} controls className='w-full h-full rounded-xl z-[5]' />
                                                            )
                                                    }
                                                    {
                                                        file.type === "image" && (
                                                            <img src={file.url} alt={file.name} className='w-full h-full rounded-xl relative z-[5]' />
                                                        )
                                                    }
                                                    {
                                                        file.type === "video" && (
                                                            <video src={file.url} controls className='w-full h-full rounded-xl relative z-[5]' />
                                                        )
                                                    }
                                                    {
                                                       file.type == "file" && (
                                                        // debe mostrar un file que es un https de pdf o docs
                                                        <iframe
                                                        src={file.url}
                                                        className="w-full h-full rounded-xl z-[5]"  // Ajusta el ancho (w-full) y la altura (h-64) según tus necesidades
                                                        title="Vista previa del documento"
                                                        >
                                                        </iframe>
                                                    )
                                                    }
                                                    <HiDotsVertical onClick={() => {
                                                        toggleMediaDots(file.name)
                                                        setFastFav(file.favorite)
                                                    }} className='text-xl cursor-pointer absolute top-1 right-1 text-black z-[12]'/>
                                                    {
                                                        mediaDots === file.name && (
                                                            <div className='absolute w-[150px] media-dots-menu left-5 bottom-[-25px] bg-[#212944] border-2 border-gray-400 p-2 rounded-lg '>
                                                                <ul className='space-y-2'>
                                                                    <a href={file.url} target='_blank' className='text-gray-400 flex justify-start items-center'><FaEye className='text-lg mr-1'></FaEye>Ver</a>
                                                                    {/* <li className='text-gray-400 flex justify-start items-center'><FaRegEdit className='text-lg mr-1'></FaRegEdit>Editar</li> */}
                                                                    <li className='text-gray-400 flex justify-start items-center cursor-pointer' onClick={() => handleFileSending(file)}><IoMdSend className='text-lg mr-1'></IoMdSend> Enviar</li>
                                                                    {
                                                                        fastFav ? (
                                                                            <a onClick={() => handleRemoveFastFavorite(encontrarCarpeta(folders, file.name), file.name)} className='text-gray-400 flex justify-start items-center cursor-pointer'>
                                                                                <FaStar className='text-lg mr-1 text-yellow-300'></FaStar>
                                                                                Quitar de Favoritos
                                                                            </a>
                                                                        ) : (   
                                                                            <a onClick={() => handleAddFastFavorite(encontrarCarpeta(folders,file.name), file.name)} className='text-gray-400 flex justify-start items-center cursor-pointer'>
                                                                                <FaRegStar className='text-lg mr-1'></FaRegStar>
                                                                                Añadir a Favoritos
                                                                            </a>
                                                                        )
                                                                    }
                                                                    <li className='text-gray-400 cursor-pointer flex justify-start items-center' onClick={() => handleDeleteFileFromFolder(encontrarCarpeta(folders, file.name), file.name)}><MdDelete className='text-lg mr-1'></MdDelete>Eliminar</li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                    <p className='text-gray-400 text-sm text-center mt-2'>{file.name}</p>
                                                </div>
                                                ))
                                            ):(
                                                getAllFiles(folders).map((file: any) => (
                                                    <div key={file.name} className='w-[150px] h-[150px] relative bg-gray-300 rounded-xl z-[10]'>
                                                        {
                                                            file.type === "audio" && (
                                                                // verificar el audio que es una direccion https
                                                                <audio src={file.url} controls className='w-full h-full rounded-xl z-[5]' />
                                                            )
                                                        }
                                                        {
                                                            file.type === "image" && (
                                                                <img src={file.url} alt={file.name} className='w-full h-full rounded-xl z-[5]' />
                                                            )
                                                        }
                                                        {
                                                            file.type === "video" && (
                                                                <video src={file.url} controls className='w-full h-full rounded-xl z-[5]' />
                                                            )
                                                        }
                                                        {
                                                           file.type == "file" && (
                                                            // debe mostrar un file que es un https de pdf o docs
                                                            <iframe
                                                            src={file.url}
                                                            className="w-full h-full rounded-xl z-[5]"  // Ajusta el ancho (w-full) y la altura (h-64) según tus necesidades
                                                            title="Vista previa del documento"
                                                            >
                                                            </iframe>
                                                        )
                                                        }
                                                        <HiDotsVertical onClick={() => {
                                                            toggleMediaDots(file.name)
                                                            setFastFav(file.favorite)
                                                        }} className='text-xl cursor-pointer absolute top-1 right-1 text-black z-[12]'/>
                                                        {
                                                            mediaDots === file.name && (
                                                                <div className='absolute w-[150px] media-dots-menu left-5 bottom-[-25px] bg-[#212944] border-2 border-gray-400 p-2 rounded-lg z-[100]'>
                                                                    <ul className='space-y-2'>
                                                                        <a href={file.url} target='_blank' className='text-gray-400 flex justify-start items-center'><FaEye className='text-lg mr-1'></FaEye>Ver</a>
                                                                        {/* <li className='text-gray-400 flex justify-start items-center'><FaRegEdit className='text-lg mr-1'></FaRegEdit>Editar</li> */}
                                                                        <li className='text-gray-400 flex justify-start items-center cursor-pointer' onClick={() => handleFileSending(file)}><IoMdSend className='text-lg mr-1'></IoMdSend> Enviar</li>

                                                                        {
                                                                        fastFav ? (
                                                                            <a onClick={() => handleRemoveFastFavorite(encontrarCarpeta(folders, file.name), file.name)} className='text-gray-400 flex justify-start items-center cursor-pointer'>
                                                                                <FaStar className='text-lg mr-1 text-yellow-300'></FaStar>
                                                                                Quitar de Favoritos
                                                                            </a>
                                                                        ) : (   
                                                                            <a onClick={() => handleAddFastFavorite(encontrarCarpeta(folders,file.name), file.name)} className='text-gray-400 cursor-pointer flex justify-start items-center'>
                                                                                <FaRegStar className='text-lg mr-1'></FaRegStar>
                                                                                Añadir a Favoritos
                                                                            </a>
                                                                        )
                                                                        }
                                                                        <li className='text-gray-400 cursor-pointer flex justify-start items-center' onClick={() => handleDeleteFileFromFolder(encontrarCarpeta(folders, file.name), file.name)}><MdDelete className='text-lg mr-1'></MdDelete>Eliminar</li>
                                                                    </ul>
                                                                </div>
                                                            )
                                                        }
                                                        <p className='text-gray-400 text-sm text-center mt-2'>{file.name}</p>
                                                    </div>
                                            )
                                           
                                            ))

                                        )
                                    }

                                    {
                                        managerSection == "Favoritos" && (
                                            //@ts-ignore
                                            Object.values(favoriteFiles).map((file: any) => (
                                                <div key={file.name} className='w-[150px] h-[150px] relative bg-gray-300 rounded-xl z-[10]'>
                                                    {
                                                        file.type === "audio" && (
                                                            // verificar el audio que es una direccion https
                                                            <audio src={file.url} controls className='w-full h-full rounded-xl' />
                                                        )
                                                    }
                                                    {
                                                        file.type === "image" && (
                                                            <img src={file.url} alt={file.name} className='w-full h-full rounded-xl' />
                                                        )
                                                    }
                                                    {
                                                        file.type === "video" && (
                                                            <video src={file.url} controls className='w-full h-full rounded-xl' />
                                                        )
                                                    }
                                                    {
                                                        file.type == "file" && (
                                                            // debe mostrar un file que es un https de pdf o docs
                                                            <iframe
                                                            src={file.url}
                                                            className="w-full h-full rounded-xl"  // Ajusta el ancho (w-full) y la altura (h-64) según tus necesidades
                                                            title="Vista previa del documento"
                                                            >
                                                            </iframe>
                                                        )
                                                    }
                                                    <HiDotsVertical onClick={() => {
                                                        toggleMediaDots(file.name)
                                                        setFastFav(file.favorite)
                                                        }} className='text-xl cursor-pointer absolute top-1 right-1 text-black z-[12]'/>
                                                    {
                                                        mediaDots === file.name && (
                                                            <div className='absolute w-[150px] media-dots-menu left-5 bottom-[-25px] bg-[#212944] border-2 border-gray-400 p-2 rounded-lg z-[15]'>
                                                                <ul className='space-y-2'>
                                                                    <a href={file.url} target='_blank' className='text-gray-400 flex justify-start items-center'><FaEye className='text-lg mr-1'></FaEye>Ver</a>
                                                                    {/* <li className='text-gray-400 flex justify-start items-center'><FaRegEdit className='text-lg mr-1'></FaRegEdit>Editar</li> */}
                                                                    <li className='text-gray-400 flex justify-start items-center cursor-pointer' onClick={() => handleFileSending(file)}><IoMdSend className='text-lg mr-1'></IoMdSend> Enviar</li>
                                                                    {
                                                                        fastFav ? (
                                                                            <a onClick={() => handleRemoveFastFavorite(encontrarCarpeta(folders, file.name), file.name)} className='text-gray-400 cursor-pointer flex justify-start items-center'>
                                                                                <FaStar className='text-lg mr-1 text-yellow-300'></FaStar>
                                                                                Quitar de Favoritos
                                                                            </a>
                                                                        ) : (   
                                                                            <a onClick={() => handleAddFastFavorite(encontrarCarpeta(folders,file.name), file.name)} className='text-gray-400 cursor-pointer flex justify-start items-center'>
                                                                                <FaRegStar className='text-lg mr-1'></FaRegStar>
                                                                                Añadir a Favoritos
                                                                            </a>
                                                                        )
                                                                    }
                                                                    <li className='text-gray-400 cursor-pointer flex justify-start items-center' onClick={() => handleDeleteFileFromFolder(encontrarCarpeta(folders, file.name), file.name)}><MdDelete className='text-lg mr-1'></MdDelete>Eliminar</li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    }
                                                    <p className='text-gray-400 text-sm text-center mt-2'>{file.name}</p>
                                                </div>
                                            ))

                                        )
                                    }

                                    {
                                        managerSection !== "Favoritos" && managerSection !== "Todos" && <Folder handleFileSending={handleFileSending} folders={folders} managerSection={managerSection} setManagerSection={setManagerSection} mediaDots={mediaDots} toggleMediaDots={toggleMediaDots} setFastFav={setFastFav} fastFav={fastFav} handleAddFastFavorite={handleAddFastFavorite} handleRemoveFastFavorite={handleRemoveFastFavorite} handleDeleteFileFromFolder={handleDeleteFileFromFolder}></Folder>
                                    }

                                </div>
                            )
                        }
                        {managerSection === 'Añadir Archivo' && <AddFile 
                        setFileManager={setFileManager} 
                        handleFileChange={handleFileChange} 
                        handleSubmit={handleSubmit} 
                        name={name} 
                        setName={setName} 
                        handleFolderSelection={handleFolderSelection} 
                        folders={folders} 
                        newFolder={newFolder} 
                        setNewFolderName={setNewFolderName} 
                        starred={starred} 
                        setStarred={setStarred} 
                        file={file}

                        audioURL={audioURL}
                        setAudioURL={setAudioURL}
                        />}
                    </div>
                    
                </div>
                

                 </>       
        )
    );
}

export default FileManagerComponent;
