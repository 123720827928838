import { axiosConfig } from "./axios.config";

const getChat = async (company, agent_id, chat_id, workspace) => {
	const res = await axiosConfig.post(
	  `${process.env.REACT_APP_API_V3}/chat_workspace/${company}/${workspace}`,
	  {
		agent_email: agent_id,
		chat_id: chat_id
	  }
	);
	return res.data;
  };

const modifyChats = async (agent_id, chat_id, action, type, company ) => {

	const body = type === 'agent' ? { "agent_id": agent_id, "chat_id": chat_id, "action": action } : {"chat_id": chat_id, "action": action}

	const res = await axiosConfig.post(`${process.env.REACT_APP_API_NEW_URL}/${type}/${company}/modify_chat`, body
	);
	return res.data;
};

const act_on_chats = async (agent_id, company, workspace, action, chats, parameters ) => {
	const res = await axiosConfig.post(`${process.env.REACT_APP_API_V3}/act_on_chats/${company}/${workspace}`,
	{
		"agent_email": agent_id,
		"chat_ids": chats,
		"action": action,
		"parameters": {
		  "destination_agent_email": parameters
		}
	  }
	);
	return res.data;
};



export { modifyChats, getChat, act_on_chats}