import React from 'react';
import "./Form.css";

interface ResponsePreviewProps {
    splitContent: string[];
    parameterValues: Record<string, string>;
}

const ResponsePreview: React.FC<ResponsePreviewProps> = ({ splitContent, parameterValues }) => {
    const previewContent = splitContent.map(content => {
        return Object.entries(parameterValues).reduce((acc, [key, value]) => {
            return acc.replace(new RegExp(key, 'g'), value);
        }, content);
    }).join("\n");

    return (
        <div className="response-preview flex flex-col justify-start items-start w-[50%] h-full p-3">
            <p className='text-white text-start text-sm pt-3 pb-1'>Vista previa de la respuesta</p>
            <textarea readOnly value={previewContent} className="preview-textarea w-full text-white" />
        </div>
    );
}

export default ResponsePreview;
