import React, { useState, useEffect, useContext, useRef } from 'react';
import { FormControl, IconButton, Select, MenuItem, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import { getAllProducts } from '../../services/products.service';
import userContext from '../../utils/userContext';
import { Product } from "./../../interfaces/parameters";
import "./../FormConfig/Form.css";

interface ModalParametersProps {
  onParameterSelected: (paramKey: string, paramValue: string) => void;
  onProductSelected: (productId: string, productName: string) => void; 
  currentProduct: { id: string; name: string } | null;
}

const ModalParameters: React.FC<ModalParametersProps> = ({onParameterSelected, onProductSelected, currentProduct, setParameters}: any) => {
  const [product, setProduct] = useState<string>('');
  const [products, setProducts] = useState<Record<string, Product>>({});
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { agent } = useContext<any>(userContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await getAllProducts('products', agent.company);
        setProducts(productsData);
  
        if (currentProduct && productsData[currentProduct.id]) {
          setProduct(currentProduct.id);
          const currentProductData = productsData[currentProduct.id];
          if (currentProductData && currentProductData.parameters) {
            setParameters(currentProductData.parameters);
          } else {
            setParameters(null);
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    fetchProducts();
  }, [agent.company, currentProduct]);
  
  


  const loadProductParameters = async (productId: string) => {
    const productData = products[productId];
    if (productData && productData.parameters) {
      setParameters(productData.parameters);      
    } else {
      setParameters(null);
    }
  };
  
  const handleProductChange = (event: SelectChangeEvent<string>) => {
    const selectedProductId = event.target.value as string;
    setProduct(selectedProductId);
    const selectedProduct = products[selectedProductId];
    
    if (selectedProduct) {
      setParameters(selectedProduct.parameters);
      onProductSelected(selectedProductId, selectedProduct.name);
    }
  };
  

  

  return (
        <div className='w-[50%] pl-2.5'>
        <p className='text-white text-sm text-start pl-1 pt-3'>Producto asociado</p>
        <FormControl fullWidth sx={{marginTop:"8px", borderColor: "white"}}>
          {product && (
            <IconButton
              onClick={() => {
                setProduct('');
                onProductSelected('', ''); 
                setParameters(null);
              }}
              color="error" 
              className='unselectProduct'
            >
              <CloseIcon />
            </IconButton>
          )}
          <Select
                labelId="product-selector-label"
                id="product-selector"
                value={product}
                displayEmpty
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <p></p>;
                    }
                    return products[selected]?.name || '';
                }}
                onChange={handleProductChange}
            >
              <MenuItem value="" className='text-center'>
                Asignar producto
              </MenuItem>
            {Object.entries(products).map(([id, product]) => (
              <MenuItem key={id} value={id} className="text-center">
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
  );
};

export default ModalParameters;
