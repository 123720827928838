import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "./FileManager.css"

const CustomAudioPlayer = ({ src }) => (
    <div className="audio-player-container" style={{
        width: '125px',
        height: '125px',
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(21 128 61)'
    }}>
        <AudioPlayer
            src={src}
            onPlay={e => console.log("onPlay")}
            showJumpControls={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            style={{
                width: 'calc(100% + 20px)', // Ajusta el ancho para llenar completamente el contenedor circular
            }}
        />
    </div>
);

export default CustomAudioPlayer;

