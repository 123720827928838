import { useState, useRef, useEffect, useContext } from 'react';
import {
    BsMicFill,
    BsEmojiSmileFill,
    BsPaperclip,
    BsFillPauseFill,
    BsXCircleFill,
    BsArrowRepeat,
} from "react-icons/bs";
import { AiOutlineDelete, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import './MessageInput.css';
import { MdOutlinePermMedia, MdOutlineFileUpload } from 'react-icons/md';
import { handleAudioDownloading, handleVideoDownloading, getMediaId, getMediaUrl } from '../../services/multimedia.service';
import { toast } from 'react-toastify';
import userContext from "../../utils/userContext";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

const MessageInput = ({ 
    onSendMessage, 
    message, 
    setMessage, 
    setMessageAudio, 
    emojiDropdown,
    setFileManager,
    audioURL,
    setAudioURL,
    filePreview,
    setFilePreview,
    fileDataPreview,
    setFileDataPreview,
    dropAreaRef,
    setIsDragging,
    setFastAnswersIsOpen
}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [isSendingAudio, setIsSendingAudio] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioPlayerRef = useRef(null);
    const fileInputRef = useRef(null);
    const { agent, clientUser } = useContext(userContext);
    const [mediaSelector, setMediaSelector] = useState(false);
    const mediaSelectorRef = useRef(null);
    const textareaRef = useRef(null);
    const audioRef = useRef(null);
    const API_URL = process.env.REACT_APP_API_URL;
    const [audioRecordingMode, setAudioRecordingMode] = useState(false) 
    const [playbackMode, setPlaybackMode] = useState(false)
    const dragCounter = useRef(0);

    // CONFIGURO CIERRE DE SELECTOR DE MEDIA

    useEffect(() => {
        function handleClickOutside(event) {
            if (mediaSelectorRef.current && !mediaSelectorRef.current.contains(event.target)) {
                setMediaSelector(false);
            }
        }

        if (mediaSelector) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mediaSelector]);

    // CONFIGURO EL FILE DROPPING

    useEffect(() => {
        const handleDragEnter = (e) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current++;
            if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
                setIsDragging(true);
            }
        };

        const handleDragLeave = (e) => {
            e.preventDefault();
            e.stopPropagation();
            dragCounter.current--;
            if (dragCounter.current === 0) {
                setIsDragging(false);
            }
        };

        const handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        const handleDrop = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                handleFileChange({ target: { files: e.dataTransfer.files } });
                e.dataTransfer.clearData();
                dragCounter.current = 0;
            }
        };

        const dropArea = dropAreaRef.current;
        dropArea.addEventListener('dragenter', handleDragEnter);
        dropArea.addEventListener('dragleave', handleDragLeave);
        dropArea.addEventListener('dragover', handleDragOver);
        dropArea.addEventListener('drop', handleDrop);

        return () => {
            dropArea.removeEventListener('dragenter', handleDragEnter);
            dropArea.removeEventListener('dragleave', handleDragLeave);
            dropArea.removeEventListener('dragover', handleDragOver);
            dropArea.removeEventListener('drop', handleDrop);
        };
    }, []);

    // INSTANCIADOR DE MEDIA RECORDER OPUS

    useEffect(() => {
        loadRecorderScripts();
        return () => {
            if (mediaRecorderRef.current) {
                try {
                    if (mediaRecorderRef.current.state !== "inactive") {
                        mediaRecorderRef.current.stop();
                    }
                } catch (error) {
                    console.error('Failed to stop the recorder:', error);
                }
            }
        };
    }, []);

    // RECORDER CONTROLS ??????

    const recorderControls = useVoiceVisualizer({
        // Define la función onStartRecording proporcionada por la biblioteca
        onStartRecording: () => {
            // Tu lógica personalizada cuando comienza la grabación
            console.log('¡Comenzó la grabación!', audioRef);
            const canvas = document.querySelector("canvas");
            canvas.setAttribute("width", "auto");
            canvas.setAttribute("height", "auto");            
            canvas.style.height ='150px !important'

            setAudioRecordingMode(true)
        },
        onStopRecording: () => {
          const canvas = document.querySelector("canvas");
          canvas.style.height ='40px !important'
          setPlaybackMode(true)
        },
        onClearCanvas: () => {
          setAudioRecordingMode(false)
          setPlaybackMode(false)
        }
    });

    // PARA SUBIR ARCHIVOS RAPIDOS

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        event.target.value = null; 

        if (!file) {
            toast.error('No se ha seleccionado ningún archivo.');
            return;
        }

        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
            try {
                const url = await getMediaUrl(agent.company, file);
                setFilePreview(true);
                setFileDataPreview({ type: "image", url: url.data.url });
                setFileManager(false);
                setMediaSelector(false);
            } catch {
                toast.error('Error al subir la imagen.');
            }
        }

        if (file.mimetype === "video/mp4") {
            try {
                const url = await getMediaUrl(agent.company, file);
                setFilePreview(true);
                setFileDataPreview({ file: file, url: url.data.url, type: "video" });
                setFileManager(false);
                setMediaSelector(false);
            }
            catch (error) {
                console.error("Error descargando el archivo de video: ", error.message);
            }
        }

        if (file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            try {
                const url = await getMediaUrl(agent.company, file);
                setFilePreview(true);
                setFileDataPreview({ type: "file", url: url.data.url });
                setFileManager(false);
                setMediaSelector(false);
            } catch {
                toast.error('Error al subir el archivo.');
            }
        }
    };

    // MANEJADORES DE GRABACION DE AUDIO

    const startRecording = async () => {
        if (!window.OpusMediaRecorder) {
            console.error("OpusMediaRecorder is not loaded yet.");
            return;
        }
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const options = { mimeType: 'audio/ogg; codecs=opus' };
            const workerOptions = {
                OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
                WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
            };
            const recorder = new window.OpusMediaRecorder(stream, options, workerOptions);
            recorder.ondataavailable = (e) => {
                const audioBlob = new Blob([e.data], { type: 'audio/ogg; codecs=opus' });
                setAudioURL(URL.createObjectURL(audioBlob));
            };
            recorder.start();
            mediaRecorderRef.current = recorder;
            setIsRecording(true);
            setMessage('');
        } catch (error) {
            console.error('Error accessing media devices:', error);
            setMessage('Recording not supported by this browser.');
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const resetAudio = () => {
        if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
        }
        setAudioURL('');
        setMessage('');
        setIsRecording(false);
    };

    // SENDERS

    const sendAudio = async () => {
        if (!audioURL) {
            alert('No audio to send');
            return;
        }
        setIsSendingAudio(true);
        console.log('Sending audio:', audioURL);
    
        try {
            const audioBlob = await fetch(audioURL).then(r => r.blob());
            console.log('Audio blob:', audioBlob);
            const now = new Date();
            const fileName = now.toISOString().replace(/[\-\:\.T]/g, '').slice(0, 14) + '.ogg';
            const audioFile = new File([audioBlob], fileName, { type: "audio/ogg; codecs=opus" });
    
            setAudioURL('');
            setMessageAudio('');
            setIsRecording(false);

            const responseId = await getMediaId(agent.company, audioFile);
            const responseUrl = await getMediaUrl(agent.company, audioFile);

            
            console.log('Audio uploaded:', responseUrl, responseId);
    
            const publicUrl = responseUrl.data.url;
            const publicId = responseId.data.id;
    
            console.log('Audio uploaded:', publicUrl, publicId);
    
            onSendMessage({
                id: publicId,
                url: publicUrl
            }, { audio: true });
    
            setIsSendingAudio(false);
        } catch (error) {
            console.error('Error sending audio:', error);
            alert('Error sending audio: ' + error.message);
            setIsSendingAudio(false);
        }
    };
    

    const sendMessage = () => {
        if (message.trim()) {
            onSendMessage(message);
            setMessage('');
        }
    };

    // KEY PRESS HANDLER

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
        if (event.ctrlKey && event.key === 'Enter') {
            setMessage(message + '\n');
        }
    };

    const handleInputChange = (event) => {
        console.log('event.target.value: ', event.target.value)
        const text = event.target.value;
        if(text === '/') {
            setFastAnswersIsOpen(true)
            setMessage('');
        }else {
            setMessage(text);
        }
        if (textareaRef.current && text.length > 44) {
            auto_grow(textareaRef);
        } else if (textareaRef.current && text.length < 44) {
            textareaRef.current.style.maxHeight = `30vh`;
        }
    };

    function auto_grow(textareaa) {
        if (textareaa.current) {
            textareaa.current.style.height = "5px";
            if(textareaa.current.scrollHeight !== 0) {
                textareaa.current.style.height = (textareaa.current.scrollHeight) + "px";
            }
        }
    }

    useEffect(() => {
        auto_grow(textareaRef);
    }, [message]);

    // CARGA DE SCRIPTS PARA GRABACION DE AUDIO

    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };
    
    const loadRecorderScripts = async () => {
        if (typeof window.OpusMediaRecorder === 'undefined') {
            try {
                await loadScript('https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js');
                await loadScript('https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js');
            } catch (error) {
                console.error("Error loading OpusMediaRecorder scripts:", error);
            }
        }
    };

    // ENVIAR ARCHIVOS DE LA PREVIEW

    const filePreviewSending = async (file) => {
        if (file.type === "image") {
            onSendMessage(file.url, true);
            setFilePreview(false);
        }
    
        if (file.type === "video") {
            if (file.file) {
                const mediaId = await getMediaId(agent.company, file);
                file.id = mediaId.id;
                onSendMessage(file, { video: true });
            } else {
                const downloadedFile = await handleVideoDownloading(file);
                const mediaId = await getMediaId(agent.company, downloadedFile);
                downloadedFile.id = mediaId.id;
                onSendMessage(downloadedFile, { video: true });
            }
        }
    
        if (file.type === "file") {
            onSendMessage(file.url, true);
            setFilePreview(false);
        }
    };

    return (
        <>
            {!isRecording && !filePreview && !audioURL && !isSendingAudio && (
                <button 
                    className="text-[#cacaca] pointer-events-none text-2xl mb-[9px]" 
                    onClick={() => setMediaSelector(true)} 
                    disabled={isRecording}
                >
                    <BsPaperclip />
                </button>
            )}
        
            {mediaSelector && (
                <div ref={mediaSelectorRef} className="bg-[#212944] border-2 border-black border-solid flex flex-col gap-2 p-3 rounded-xl absolute bottom-[80px] text-gray-300 w-[180px] h-[80px]" onClick={() => setMediaSelector(false)}>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        setFileManager(true);
                        setMediaSelector(false);
                    }} className='flex items-center justify-start gap-1'><MdOutlinePermMedia/><p>Gestor Media</p></button>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        fileInputRef.current && fileInputRef.current.click();
                    }} className='flex items-center justify-start gap-1'><MdOutlineFileUpload/> <p>Subida rápida</p></button>
                </div>
            )}
        
            {isSendingAudio ? (
                <div className=" flex items-center h-10 w-10 ">
                    <BsArrowRepeat className="spinner w-[30px] h-[30px]" />
                    <span className='text-white w-[50px]'>Enviando🔥...</span>
                </div>
            ) : isRecording ? (
                <div className="items-center h-10 w-10 flex justify-between">
                    <BsArrowRepeat className="spinner text-xl" />
                    <span className='text-white mr-2'>Grabando audio...</span>
                    <button className="mx-2 text-white" onClick={stopRecording}>
                        <BsFillPauseFill />
                    </button>
                </div>
            ) : audioURL ? (
                <>
                    <audio ref={audioPlayerRef} src={audioURL} controls className="audio-player" />
                    <button className="mx-2 text-white" onClick={resetAudio}>
                        <BsXCircleFill />
                    </button>
                    <button className="ml-2" onClick={sendAudio}>
                        <IoMdSend className='text-2xl text-green-400'/>
                    </button>
                </>
            ) : (
                <>
                    {(audioRecordingMode && !playbackMode) && <p className='text-white w-1/2 text-right mr-3 text-sm'>Grabando...</p>}
    
                    {!audioRecordingMode && !filePreview && (
                        <div className="flex items-end justify-between py-1 px-4 bg-greyBackground w-full rounded-lg mx-2 min-h-[40px] max-h-none">
                            <>
                                <button 
                                    className="border-none bg-none hidden md:block mb-[6px]" 
                                    disabled={isRecording}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        emojiDropdown(true);
                                    }}
                                >
                                    <BsEmojiSmileFill className='text-white text-xl'/>
                                </button>
                                <input 
                                    type="file" 
                                    ref={fileInputRef} 
                                    onChange={handleFileChange} 
                                    accept="image/*, video/mp4, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                    style={{display: 'none'}}
                                />
                                <textarea
                                    className="!p-0 !mb-[6px] bg-slate-400 !resize-none placeholder-white input-field md:text-base !text-white text-sm !border-none flex items-center !min-h-[20px]"
                                    placeholder="Escribe un mensaje"
                                    value={message}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={handleKeyPress}
                                    disabled={isRecording}
                                    ref={textareaRef}
                                    style={{ resize: 'vertical', background: 'none' }} 
                                />
                            </>
                            <div className={`${audioRecordingMode ? 'block' : 'hidden'} flex items-center justify-between w-full`}>
                                <VoiceVisualizer ref={audioRef} controls={recorderControls} 
                                    barWidth={2}
                                    gap={1}
                                    isControlPanelShown='false'
                                    mainContainerClassName={'flex text-white items-center justify-center flex-row-reverse w-full'}
                                    canvasContainerClassName={'!ml-3 max-h-[40px] w-full flex items-center justify-center'}
                                    progressIndicatorTimeClassName={'text-white'}
                                    width='100%'
                                    height={'auto'}
                                />
                            </div>
                        </div>
                    )}
    
                    {(audioRecordingMode && !playbackMode) && (
                        <>
                            <button onClick={resetAudio}>
                                <AiOutlineCloseCircle className='text-[#f44336] text-2xl'/>
                            </button>
                            <button onClick={stopRecording}>
                                <AiOutlineCheckCircle className='ml-2 text-green-400 text-2xl'/>
                            </button>
                        </>
                    )}
                    {playbackMode && !filePreview && (
                        <button className="bg-none px-2" onClick={sendAudio}>
                            <IoMdSend className='text-2xl text-green-400'/>
                        </button>
                    )}
                </>
            )}
        
            {message && !filePreview && (
                <button className="bg-none px-2" onClick={sendMessage}>
                    <IoMdSend className='text-2xl text-green-400'/>
                </button>
            )}
            {(!audioRecordingMode && !message && !filePreview && !audioURL && !isSendingAudio) && (
                <button className="bg-none px-2  pointer-events-none mb-[9px]" onClick={() => startRecording()}>
                    <BsMicFill className='text-2xl text-[#cacaca]'/>
                </button>
            )}
            {filePreview && (
                <div>
                    {fileDataPreview.type === "image" ? (
                        <div className='flex'>
                            <img src={fileDataPreview.url} className='h-12 w-12 rounded-lg' />
                            <button className="mx-2" onClick={() => {
                                setFilePreview(false);
                                setFileDataPreview(null);
                            }}>
                                <BsXCircleFill className='text-xl text-red-500' />
                            </button>
                            <button onClick={() => filePreviewSending(fileDataPreview)} className="ml-2">
                                <IoMdSend className='text-2xl text-green-400'/>
                            </button>
                        </div>
                    ) : fileDataPreview.type === "video" ? (
                        <div className='flex'>
                            <video src={fileDataPreview.url} className='h-12 w-12 rounded-lg'></video>
                            <button className="mx-2" onClick={() => {
                                setFilePreview(false);
                                setFileDataPreview(null);
                            }}>
                                <BsXCircleFill className='text-xl text-red-500' />
                            </button>
                            <button onClick={() => filePreviewSending(fileDataPreview)} className="ml-2">
                                <IoMdSend className='text-2xl text-green-400'/>
                            </button>
                        </div>
                    ) : (
                        <div className='flex'>
                            <iframe src={fileDataPreview.url} className='h-12 w-12 rounded-lg'></iframe>
                            <button className="mx-2" onClick={() => {
                                setFilePreview(false);
                                setFileDataPreview(null);
                            }}>
                                <BsXCircleFill className='text-xl text-red-500' />
                            </button>
                            <button onClick={() => filePreviewSending(fileDataPreview)} className="ml-2">
                                <IoMdSend className='text-2xl text-green-400'/>
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>
    );    
};

export default MessageInput;