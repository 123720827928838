import React, { useEffect, useState, useRef, useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Modal } from "flowbite-react";
import MultiSelect from "./MultiSelect";
import userContext from "../../utils/userContext";
import ModalParameters from "../ModalConfig/ModalParameters";
import ResponsePreview from "./ResponsePreview";

import "./Form.css";
import { actionTable } from "../../services/Tables.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextareaAutosize } from "@mui/material";

const RESPONSES_MODEL = {
  name: "",
  created_at: new Date(),
  creator: "New Creator",
  split_content: [""],
  product: { id: "", name: "" },
};

const TAGS_MODEL = {
  name: "",
  color: "",
  icon_name: "",
  description: "",
  category: "",
  is_visible: "",
  created_at: new Date(),
};

const PURE_ADS_MODEL = {
  ad_id: "",
  name: "",
  description: "",
  tags_id_and_name: [],
};
const OTHER_SOURCERS_MODEL = {
  name: "",
  description: "",
  tags_id_and_name: [],
};
const TRIGGERS_MODEL = {
  posibilidad: "",
  corte: "",
  other_source_id_and_name: [],
  pure_ad_id_and_name: [],
};
const IMPURE_ADS_MODEL = {
  url: "",
  pure_ad_id_and_name: [],
};

type Props = {
  tableSelected: string;
  action: string;
  selectedRow: any;
  tableGetData: () => Promise<void>;
  closeModal: (value: React.SetStateAction<boolean>) => void;
};

type valueData = {
  id: string;
  name: string;
};

interface TriggersType {
  posibilidad: string;
  corte: string;
  other_source_id_and_name: valueData[];
  pure_ad_id_and_name: valueData[];
}

const FormConfig: React.FC<Props> = ({
  tableSelected,
  action,
  selectedRow,
  tableGetData,
  closeModal,
}) => {
  const { agent } = useContext<any>(userContext);

  const [formType, setFormType] = useState(tableSelected);
  const [responsesForm, setResponsesForm] = useState(RESPONSES_MODEL);
  const [tagsForm, setTagsForm] = useState(TAGS_MODEL);
  const [pureAdsForm, setPureAdsForm] = useState(PURE_ADS_MODEL);
  const [impureAdsForm, setImpureAdsForm] = useState(IMPURE_ADS_MODEL);
  const [otherSourcersForm, setOtherSourcersForm] =
    useState(OTHER_SOURCERS_MODEL);
  const [triggersForm, setTriggersForm] =
    useState<TriggersType>(TRIGGERS_MODEL);
  const [allTags, setAllTags] = useState([]);
  const [listDropdown, setListDropdown] = useState([]);
  const [listDropdownPureAd, setListDropdownPureAd] = useState([]);
  const [parameters, setParameters] = useState<any>(null);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaRefs = useRef<Array<HTMLTextAreaElement | null>>([]);
  const textInputRef = useRef<HTMLDivElement>(null);

  const [activeTextAreaIndex, setActiveTextAreaIndex] = useState<number | null>(
    null
  );
  const [parameterValues, setParameterValues] = useState<
    Record<string, string>
  >({});

  const handleParameterSelection = (paramKey: string, paramValue: string) => {
    if (activeTextAreaIndex !== null) {
      const updatedSplitContent = [...responsesForm.split_content];
      const currentText = updatedSplitContent[activeTextAreaIndex] || "";

      const newText = `{{${paramKey}}}`;
      updatedSplitContent[activeTextAreaIndex] = currentText + newText;

      setResponsesForm({
        ...responsesForm,
        split_content: updatedSplitContent,
      });

      const newParameterValues = {
        ...parameterValues,
        [`{{${paramKey}}}`]: paramValue,
      };
      setParameterValues(newParameterValues);

      // Espera a que el estado se actualice y el DOM se renderice
      setTimeout(() => {
        const textareaElement = textareaRefs.current[activeTextAreaIndex];
        if (textareaElement) {
          textareaElement.focus();
          const newCursorPosition = currentText.length + newText.length;
          textareaElement.setSelectionRange(
            newCursorPosition,
            newCursorPosition
          );
        }
      }, 0);
    }
  };

  const getTextareaStyle = (index: number) => {
    if (index === 0) {
      return "textarea-input first-message bg-[#1a2238] text-[#35cab4]";
    } else {
      return "textarea-input first-message bg-[#1a2238] text-[#35cab4]";
    }
  };

  const getPlaceholderTextArea = (index: number) => {
    if (index === 0) {
      return "Este es un ejemplo de mensaje que conformaría una response. El objetivo de separar una respuesta en varios mensajes es dividir mejor la información, de manera que un único mensaje no quede tan sobrecargado de texto.";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (action == "edit") {
      // Here the edit forms
      if (tableSelected === "responses") {
        if (selectedRow.product) {
          setResponsesForm({ ...selectedRow, product: selectedRow.product });
        }
        const updatedSplitContent = Array.isArray(selectedRow.split_content)
          ? selectedRow.split_content
          : [selectedRow.split_content || ""]; // Asegura un valor por defecto como string vacío

        console.log("selectedRow.split_content: ", selectedRow.split_content);

        console.log("updatedSplitContent: ", updatedSplitContent);
        setResponsesForm({
          ...selectedRow,
          split_content: updatedSplitContent,
        });
      } else if (tableSelected === "tags") {
        setTagsForm(selectedRow);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(selectedRow);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(selectedRow);
      } else if (tableSelected === "triggers") {
        setTriggersForm(selectedRow);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(selectedRow);
      }
    } else {
      if (tableSelected === "responses") {
        setResponsesForm(RESPONSES_MODEL);
      } else if (tableSelected === "tags") {
        setTagsForm(TAGS_MODEL);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(PURE_ADS_MODEL);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(OTHER_SOURCERS_MODEL);
      } else if (tableSelected === "triggers") {
        setTriggersForm(TRIGGERS_MODEL);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(IMPURE_ADS_MODEL);
      }
    }
  }, [action, selectedRow, tableSelected]);

  function auto_grow(textarea: React.RefObject<HTMLTextAreaElement>) {
    if (textarea.current) {
      textarea.current.style.height = "5px";
      textarea.current.style.height = textarea.current.scrollHeight + "px";
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (tableSelected === "responses") {
      if (e.target.name === "split_content") {
        const index = parseInt(e.target.id.charAt(e.target.id.length - 1));
        let newArr = [...responsesForm.split_content];
        newArr[index] = e.target.value;
        setResponsesForm({ ...responsesForm, [e.target.name]: newArr });
        console.log(responsesForm);

        auto_grow(textareaRef);
      } else {
        setResponsesForm({ ...responsesForm, [e.target.name]: e.target.value });
      }
    } else if (tableSelected === "tags") {
      setTagsForm({ ...tagsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "pure_ads") {
      setPureAdsForm({ ...pureAdsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "other_sources") {
      setOtherSourcersForm({
        ...otherSourcersForm,
        [e.target.name]: e.target.value,
      });
    } else if (tableSelected === "triggers") {
      console.log("triggersForm: ", triggersForm);
      setTriggersForm({ ...triggersForm, [e.target.name]: e.target.value });
    }
  };

  const notify = (text: string) => toast(text);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formToSend: any;
    switch (tableSelected) {
      case "responses":
        console.log("send split content: ", responsesForm.split_content);
        // const processedContent = responsesForm.split_content.map(content => {
        //     return Object.entries(parameterValues).reduce((acc, [key, value]) => {
        //         return acc.replace(new RegExp(key, 'g'), value);
        //     }, content);
        // }).join("\n");

        formToSend = {
          ...responsesForm,
          product: responsesForm.product,
          split_content: responsesForm.split_content,
        };

        break;
      case "tags":
        formToSend = { ...tagsForm };
        break;
      case "pure_ads":
        formToSend = { ...pureAdsForm };
        break;
      case "other_sources":
        formToSend = { ...otherSourcersForm };
        break;
      case "triggers":
        formToSend = { ...triggersForm };
        break;
      case "impure_ads":
        formToSend = { ...impureAdsForm };
        break;
      default:
        console.error("Tipo de formulario no reconocido");
        return;
    }

    // Validaciones específicas para 'triggers'
    if (tableSelected === "triggers") {
      if (formToSend.posibilidad === "") {
        notify("Debe completar el campo posibilidad");
        return;
      }
      if (formToSend.corte === "") {
        notify("Debe completar el campo corte");
        return;
      }
      if (
        formToSend.other_source_id_and_name.length > 0 &&
        formToSend.pure_ad_id_and_name.length > 0
      ) {
        notify("No puede seleccionar Other Sources y Pure ad al mismo tiempo");
        return;
      }
      if (
        formToSend.other_source_id_and_name.length === 0 &&
        formToSend.pure_ad_id_and_name.length === 0
      ) {
        notify("Debe seleccionar un Other Source o un Pure Ad");
        return;
      }
    }

    console.log("Datos procesados para enviar:", formToSend);

    // Enviar el formulario
    actionTable(
      action,
      formToSend,
      tableSelected,
      selectedRow.id,
      agent.company
    ).then((data) => {
      tableGetData();
      closeModal(false);
    });
  };

  const handleParameterClick = (paramName: any, paramValue: any) => {
    handleParameterSelection(paramName, paramValue);
  };

  const handleProductSelection = (productId: string, productName: string) => {
    setResponsesForm({
      ...responsesForm,
      product: { id: productId, name: productName },
    });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="form-config-container"
    >
      <div className="w-full gap-5 flex flex-col items-center">
        <div
          className={`${
            formType == "responses" && action != "delete" ? "w-full" : "w-full"
          }`}
        >
          {action !== "delete" && (
            <>
              {formType === "responses" && (
                <>
                  <div className="p-2">
                    <h2 className="text-start text-2xl text-white">
                      <b>Editor de responses</b>
                    </h2>
                    <p className="text-start">
                      Crea o edita una respuesta pre cargada que podrá ser
                      utilizada para el copilot, o bien por los agentes a través
                      de respuestas rápidas (rayito) dentro de los chats.
                      <br />
                      Cada respuesta puede tener múltiples mensajes, que serán
                      enviados uno a continuación del otro.
                      <br />
                      Puedes añadir un producto a la respuesta y así utilizar
                      sus parámetros (por ejemplo: precio). De esta forma, la
                      respuesta siempre utilizara el valor actualizado del
                      producto.
                    </p>
                  </div>
                  <div className="flex formWhite">
                    <div className="w-[50%]">
                      <div className="flex p-3">
                        <div className="w-[50%]">
                          <p className="text-white text-sm text-start pl-2 pt-3">
                            Nombre
                          </p>
                          <TextField
                            className="nameFieldResponses"
                            id="name"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={responsesForm.name}
                          />
                        </div>
                        {/* @ts-ignore */}
                        <ModalParameters
                          onParameterSelected={handleParameterSelection}
                          onProductSelected={handleProductSelection}
                          currentProduct={responsesForm.product}
                          //@ts-ignore
                          setParameters={setParameters}
                        />
                      </div>
                      <div className="pl-5">
                        <p className="text-white text-sm text-start">
                          Parámetros del producto
                        </p>
                        {parameters && Object.keys(parameters).length > 0 ? (
                          Object.entries(parameters).map(
                            ([paramName, paramValue], index) => (
                              <>
                                <ul key={index} className="flex">
                                  {/* @ts-ignore */}
                                  <li
                                    onClick={() =>
                                      handleParameterClick(
                                        paramName,
                                        paramValue
                                      )
                                    }
                                    className=" text-white bg-[#52A7D5] cursor-pointer p-2 m-2 h-7 flex text-center items-center rounded"
                                  >
                                    {paramName}
                                  </li>
                                </ul>
                              </>
                            )
                          )
                        ) : (
                          <ul>
                            <li className="p-1 text-white m-2">
                              {parameters
                                ? "No tiene parámetros."
                                : "Seleccione un producto."}
                            </li>
                          </ul>
                        )}
                      </div>
                      <div className="overflow-y-auto p-2">
                        {responsesForm.split_content.map((item: string, i) => (
                          <>
                            <div className="flex justify-between">
                              <p className="text-start text-sm text-white pl-3">
                                {i + 1}° Mensaje
                              </p>
                              {responsesForm.split_content.length >= 2 && (
                                <button
                                  className="pl-2 relative bg-white text-black px-2 py-1 rounded"
                                  onClick={() => {
                                    let newArr = [
                                      ...responsesForm.split_content,
                                    ];
                                    newArr.splice(i, 1);
                                    setResponsesForm({
                                      ...responsesForm,
                                      split_content: newArr,
                                    });
                                  }}
                                >
                                  Eliminar mensaje
                                </button>
                              )}
                            </div>

                            <div className="form-array-actions" key={i}>
                              <div className="container-multiple-actions-btn">
                                {responsesForm.split_content.length ==
                                  i + 1 && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      let newArr = [
                                        ...responsesForm.split_content,
                                      ];
                                      newArr[i + 1] = "";
                                      setResponsesForm({
                                        ...responsesForm,
                                        split_content: newArr,
                                      });
                                    }}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                              <TextareaAutosize
                                ref={(el: any) =>
                                  (textareaRefs.current[i] = el)
                                }
                                placeholder={getPlaceholderTextArea(i)}
                                onFocus={() => setActiveTextAreaIndex(i)}
                                id={`split_content-${i}`}
                                name="split_content"
                                onChange={(e) => handleChange(e)}
                                value={item}
                                className={getTextareaStyle(i)}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    <ResponsePreview
                      parameterValues={parameterValues}
                      splitContent={responsesForm.split_content}
                    />
                  </div>
                </>
              )}
              {formType === "tags" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.name}
                    sx={{ marginLeft: "3px" }}
                    className="tagsTextField"
                  />
                  <TextField
                    id="color"
                    name="color"
                    label="Color"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.color}
                    className="tagsTextField"
                  />
                  <TextField
                    id="icon_name"
                    name="icon_name"
                    label="Icon name"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.icon_name}
                    className="tagsTextField"
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Descripcion"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.description}
                    className="tagsTextField"
                  />
                  <TextField
                    id="category"
                    name="category"
                    label="Category"
                    onChange={(e) => handleChange(e)}
                    value={tagsForm.category}
                    className="tagsTextField"
                  />
                </>
              )}
              {formType === "pure_ads" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.name}
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.description}
                  />
                  <TextField
                    id="ad_id"
                    name="ad_id"
                    label="AD ID"
                    onChange={(e) => handleChange(e)}
                    value={pureAdsForm.ad_id}
                  />
                  {/* @ts-ignore */}
                  {allTags.length > 1 && (
                    <MultiSelect
                      data={allTags}
                      //@ts-ignore
                      defaultSelected={pureAdsForm.tags}
                      onchange={(value: string[]) =>
                        setPureAdsForm({
                          ...pureAdsForm,
                          //@ts-ignore
                          tags_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "other_sources" && (
                <>
                  <TextField
                    id="name"
                    label="Name"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={otherSourcersForm.name}
                  />
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    onChange={(e) => handleChange(e)}
                    value={otherSourcersForm.description}
                  />
                  {/* @ts-ignore */}
                  {allTags.length > 1 && (
                    <MultiSelect
                      data={allTags}
                      //@ts-ignore
                      defaultSelected={pureAdsForm.tags}
                      onchange={(value: string[]) =>
                        setOtherSourcersForm({
                          ...otherSourcersForm,
                          //@ts-ignore
                          tags_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "triggers" && (
                <>
                  <TextField
                    id="posibilidad"
                    name="posibilidad"
                    label="Posibilidad"
                    onChange={(e) => handleChange(e)}
                    value={triggersForm.posibilidad}
                  />
                  <TextField
                    id="corte"
                    name="corte"
                    label="Corte"
                    type="number"
                    onChange={(e) => handleChange(e)}
                    value={triggersForm.corte}
                  />
                  {listDropdown.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      data={listDropdown}
                      name={"Other Sources"}
                      multiple={false}
                      disabled={
                        //@ts-ignore
                        triggersForm.pure_ad_id_and_name.id ? true : false
                      }
                      onchange={(value: valueData[]) =>
                        //@ts-ignore
                        setTriggersForm({
                          ...triggersForm,
                          other_source_id_and_name: value,
                        })
                      }
                    />
                  )}
                  {listDropdownPureAd.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      data={listDropdownPureAd}
                      name={"Pure Ad"}
                      disabled={
                        //@ts-ignore
                        triggersForm.other_source_id_and_name.id ? true : false
                      }
                      multiple={false}
                      onchange={(value: valueData[]) =>
                        //@ts-ignore
                        setTriggersForm({
                          ...triggersForm,
                          pure_ad_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
              {formType === "impure_ads" && (
                <>
                  <TextField
                    id="url"
                    label="Url"
                    name="url"
                    onChange={(e) => handleChange(e)}
                    value={impureAdsForm.url}
                  />
                  {listDropdown.length > 1 && (
                    //@ts-ignore
                    <MultiSelect
                      multiple={false}
                      name={"Impure Ads"}
                      data={listDropdown}
                      onchange={(value: string[]) =>
                        setImpureAdsForm({
                          ...impureAdsForm,
                          //@ts-ignore
                          pure_ad_id_and_name: value,
                        })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal.Footer
        className="flex justify-center"
        style={{ paddingBottom: 0 }}
      >
        <Button className="bg-principalColor" type="submit">
          Confirmar
        </Button>
        <Button color="failure" onClick={() => closeModal(false)}>
          Cancelar
        </Button>
      </Modal.Footer>
      <ToastContainer
        toastStyle={{
          border: "1px solid white",
          backgroundColor: "#212944",
        }}
      />
    </Box>
  );
};

export default FormConfig;
